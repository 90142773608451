import React from "react";
import { colors } from "../../Theme/Theme";

export const tableViewCardContentStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

export const tableViewCardStyle: React.CSSProperties = {
  margin: "0.5rem",
  width: "13rem",
};

export const tableViewAllCards: React.CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "normal",
  maxHeight: "60vh",
  overflowY: "auto",
  marginTop: "0.5rem",
  flex: 3,
};

export const cardUserNameStyle: React.CSSProperties = {
  fontWeight: "bold",
  fontSize: "1rem",
  textAlign: "center",
};
export const filterButton: React.CSSProperties = {
  marginLeft: "0.5rem",
  display: "flex",
};
export const employeeDesignation: React.CSSProperties = {
  textAlign: "center",
  marginTop: "0",
  paddingTop: "0",
};

export const cardDetailingStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: colors.innerBackground,
  borderRadius: "8rem",
  flexDirection: "column",
};

export const cardReporting: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  paddingLeft: "1rem",
  paddingTop: "0.5rem",
};

export const contentStyling: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "0.5rem",
};

export const contentInsideStyling: React.CSSProperties = {
  paddingLeft: ".2rem",
  paddingTop: "0",
  marginTop: "0",
};

export const treeContainer: React.CSSProperties = {
  marginLeft: "2rem",
  backgroundColor: "white",
  width: "90%",
  height: "25rem",
  display: "flex",
  flexDirection: "row",
};

export const treeLine: React.CSSProperties = {
  width: "75%",
  backgroundColor: "white",
};

export const cardStylingTree: React.CSSProperties = {
  marginLeft: "1rem",
  marginTop: "1rem",
  marginRight: "1rem",
  width: "13rem",
  height: "10rem",
};
