import React from "react";
import { colors } from "../../Theme/Theme";

export const profileMain: React.CSSProperties = {
  padding: "1rem",
  paddingTop: "0rem",
  paddingBottom: "0rem",
};

export const container: React.CSSProperties = {
  display: "flex",
};

export const inputBox: React.CSSProperties = {
  marginBottom: "1rem",
  height: "2.5rem",
  backgroundColor: colors.innerBackground,
  border: "1px solid #FBFAFE",
  color: colors.innerBackgroundLight,
};

export const inputEditBox: React.CSSProperties = {
  marginBottom: "1.5rem",
  height: "2.5rem",
  width: "100%",
  backgroundColor: colors.background,
};

export const arrowStyle: React.CSSProperties = {
  fontSize: "20px",
  marginTop: "12px",
};

export const imageIconStyle: React.CSSProperties = {
  width: "3rem",
  height: "3rem",
  marginLeft: "1rem",
};

export const profileNameStyle: React.CSSProperties = {
  paddingLeft: "1rem",
  marginTop: "12px",
  fontSize: "20px",
};

export const editButtonStyle: React.CSSProperties = {
  marginTop: ".5rem",
  marginRight: "4rem",
  width: "8rem",
  backgroundColor: colors.primary,
  fontWeight: 700,
};

export const currentPathButtonStyle: React.CSSProperties = {
  marginTop: ".5rem",
  marginRight: "4rem",
  width: "8rem",
  fontWeight: 700,
};

export const fixedDetailsStyle: React.CSSProperties = {
  maxHeight: "91vh",
  display: "flex",
  overflowY: "auto",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "3rem",
  width: "20rem",
  backgroundColor: "#cce5e5",
  borderRadius: 8,
};

export const employeeImageStyle: React.CSSProperties = {
  width: "8rem",
  height: "8rem",
  marginTop: "1rem",
  background: "white",
  borderRadius: "10px",
};

export const savedDetailsStyling: React.CSSProperties = {
  border: "none",
  fontSize: "1rem",
  backgroundColor: colors.innerBackground,
  color: colors.innerBackgroundLight,
};

export const fixedDetailsHeadingStyle: React.CSSProperties = {
  margin: "1rem",
  marginRight: "0rem",
};

export const profileSections: React.CSSProperties = {
  margin: "1rem",
  marginRight: "0rem",
  width: "50%",
  height: "80%",
  overflow: "auto",
};

export const headingStyle: React.CSSProperties = {
  paddingLeft: "0.3rem",
  fontSize: "20px",
  color: colors.innerBackgroundDark,
};

export const headingDetails: React.CSSProperties = {
  fontSize: "20px",
  color: colors.innerBackgroundDark,
  marginTop: "1rem",
  fontWeight: 600,
  textAlign: "center",
};

export const profileNavBarStyle: React.CSSProperties = {
  margin: "1rem",
  width: "16rem",
};

export const floatButtonStyle: React.CSSProperties = {
  width: "8rem",
  fontWeight: 600,
  flex: "end",
  marginRight: "0.5rem",
};

export const paraStyles: React.CSSProperties = {
  margin: "0rem",
  marginBottom: "1rem",
  paddingLeft: "0.3rem",
  color: colors.innerBackgroundDark,
  fontWeight: 600,
};

export const skillList: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  color: "grey",
  marginLeft: "1rem",
};
