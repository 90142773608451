export const noSkillDiv: React.CSSProperties = {
  display: "flex",
  alignContent: "center",
};

export const mainDiv: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: "80vh",
  overflowY: "auto",
  marginTop: "3rem",
};

export const pageTitle: React.CSSProperties = {
  display: "flex",
  position: "relative",
  justifyContent: "center",
};

export const titleText: React.CSSProperties = {
  display: "flex",
  alignContent: "center",
  fontWeight: "bold",
  marginTop: "1.5rem",
};
export const skillNameStyle: React.CSSProperties = {
  display: "flex",
  alignContent: "center",
  fontWeight: "bold",
  width: "7rem",
  fontSize: "1rem",
};

export const skillCardStyle: React.CSSProperties = {
  width: "48rem",
  height: "7rem",
};

export const levelEditIcon: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const skillCardDiv: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  width: "45rem",
  height: "1.5rem",
};

export const radioDivStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
};

export const radioGroupStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  paddingTop: "4px",
};

export const cardContainer: React.CSSProperties = {
  height: "70vh",
  overflowY: "auto",
};

export const buttonStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
};

export const iconStyle: React.CSSProperties = {
  marginLeft: "10px",
};

export const updateButton: React.CSSProperties = {
  marginRight: "1rem",
};

export const cardDiv: React.CSSProperties = {
  marginBottom: "0.5rem",
};

export const addSkillDiv: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  position: "relative",
  marginTop: "1rem",
};

export const addNewSkillHeading: React.CSSProperties = {
  fontWeight: "bold",
  padding: "6px",
  marginRight: "2rem",
};

export const autoComplete: React.CSSProperties = {
  width: 180,
  paddingRight: "6px",
  borderRadius: "6px",
};

export const selectLevel: React.CSSProperties = {
  width: 180,
  paddingRight: "6px",
  borderRadius: "6px",
};
export const addSkillStyle: React.CSSProperties = {
  paddingLeft: "6px",
};
