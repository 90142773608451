import React, { FC } from "react";
import { Input } from "antd";

import {
  inputEditBox,
  savedDetailsStyling,
} from "../../Styles/ProfilePage/Profile";

interface InputBoxProps {
  data: string | undefined;
  isEditable: boolean;
  setState: React.Dispatch<React.SetStateAction<string | undefined>>;
  placeholder: string;
}

export const InputBox: FC<InputBoxProps> = ({
  data,
  isEditable,
  setState,
  placeholder,
}) => {
  const inputStyle = {
    ...inputEditBox,
    ...savedDetailsStyling,
  };

  return (
    <div>
      <Input
        value={data}
        style={!isEditable ? inputStyle : inputEditBox}
        required
        disabled={!isEditable}
        onChange={(e) => setState(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};
