export const infoDiv: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "1rem",
};

export const designationStyle: React.CSSProperties = {
  display: "flex",
  alignContent: "center",
  justifyContent: "space-between",
  color: "#494744",
};

export const hrStyle: React.CSSProperties = { width: "90%" };

export const emailDiv: React.CSSProperties = {
  display: "flex",
  alignContent: "center",
  marginTop: "0.5rem",
  color: "#0D5F82",
  cursor: "pointer",
};

export const phoneDiv: React.CSSProperties = {
  display: "flex",
  alignContent: "center",
  marginTop: "6px",
  color: "#0D5F82",
  cursor: "pointer",
};

export const skillInfo: React.CSSProperties = {
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  height: "auto",
  overflowY: "auto",
};

export const projectInfo: React.CSSProperties = {
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  marginRight: "3rem",
  height: "auto",
  overflowY: "auto",
  width: "17rem",
};
