import { AutoComplete, Button, Modal, notification } from "antd";
import React, { useState } from "react";
import User from "../../interface/User";
import { FilterData } from "../../interface/FilterData";
import useFetch from "../../Hooks/useFetch";
import { Typography } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { container } from "../../Styles/ProfilePage/Profile";
import { userRoles } from "../../enums/Roles";
import { AllProject } from "../../interface/AllProject";
const { Text } = Typography;

const UserCard = ({
  data,
  projectId,
  userList,
  filterData,
  type,
  getAllProjectData,
}: {
  data: String;
  projectId: number;
  userList: User[];
  filterData: FilterData[];
  type: string;
  getAllProjectData: () => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);
  const { call } = useFetch("PUT");

  const userType = localStorage.getItem("role");

  const selectName = (value: string) => setSelectedName(value);
  const clearSelection = () => setSelectedName("");

  const submitHandler = () => {
    if (selectedName === "") {
      notification.warning({
        message: `Please select a ${type} name`,
      });
      return;
    }

    const selectedUserData = userList.filter(
      (user) => user.name === selectedName
    );

    const changeData =
      type === "manager"
        ? {
            projectId: projectId,
            managerId: selectedUserData[0].id,
          }
        : {
            projectId: projectId,
            leadId: selectedUserData[0].id,
          };
    call(
      `/project/${type}`,
      changeData,
      (data) => {
        notification.success({
          message: "Updated",
        });
        getAllProjectData();
      },
      (error) => {
        notification.warning({
          message: "Failed to Change",
        });
      }
    );
    setIsModalOpen(false);
  };

  const changeHandler = () => {
    setIsModalOpen(true);
  };

  const titleValue = `Change ${type} Name `;
  const placeholderValue = `${type} Name`;

  return (
    <div>
      <div
        style={{
          ...container,
        }}
      >
        <Text style={{ margin: "0px 8px" }}>{data}</Text>
        {(userType === userRoles.Allocation ||
          userType === userRoles.Admin) && (
          <FormOutlined onClick={changeHandler} style={{ cursor: "pointer" }} />
        )}
      </div>
      {isModalOpen && (
        <Modal
          style={{ top: "50px" }}
          title={titleValue}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={
            <Button
              key="submit"
              type="primary"
              onClick={submitHandler}
              style={{ marginTop: "1rem" }}
            >
              Save
            </Button>
          }
        >
          <AutoComplete
            style={{ width: "100%", marginTop: "0.5rem" }}
            placeholder={placeholderValue}
            options={filterData}
            className="search-user"
            filterOption={true}
            onSelect={selectName}
            onClear={clearSelection}
          />
        </Modal>
      )}
    </div>
  );
};

export default UserCard;
