import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../constant";

function useFetch(method: string) {
  const navigate = useNavigate();
  const token = localStorage.getItem("idToken");
  const call = (
    apiPath: string,
    postData: any,
    onSuccess: (data: any) => void,
    onError: (data: any) => void
  ) => {
    fetch(`${BASE_URL}${apiPath}`, {
      method: method,
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 403) navigate("/session-error");
        if (response.status === 401) {
          navigate("/session-error");
          throw new Error("UnAuthorized");
        }
        if (response.status === 409) {
          throw new Error("Already Found");
        }
        if (response.status === 400) {
          return response.json().then((data) => {
            if (data.message === "Availability exceeded than 100") {
              throw new Error(data.message);
            } else {
              throw new Error("Other 400 Error");
            }
          });
        }
        return response.json();
      })
      .then((data) => onSuccess(data.data))
      .catch((error) => onError(error));
  };
  return { call };
}
export default useFetch;
