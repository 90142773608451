import { colors } from "../Theme/Theme";

export const innerContainerLogin: React.CSSProperties = {
  minHeight: "100vh",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "row",
  color: colors.innerBackgroundLight,
};
export const innerContainer: React.CSSProperties = {
  width: "100%",
  height: "90vh",
  overflowY: "auto",
  borderRadius: "2vw",
  backgroundColor: "#f2f5fa",
  border: ".1vw solid #f7f8fe",
};
