export const leaveHeader: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
};

export const legendList: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  marginTop: "1rem",
};

export const legend: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  marginRight: "1rem",
};

export const colorBox: React.CSSProperties = {
  width: "0.8rem",
  height: "0.8rem",
  border: "0.5px solid #444",
  marginRight: "0.3rem",
};

export const legendText: React.CSSProperties = {
  fontSize: "0.9rem",
};

export const linkBox: React.CSSProperties = {
  overflowX: "auto",
  border: "0.5px solid #444",
  padding: "0.5rem",
  borderRadius: "10px",
};
