import React, { useEffect, useState } from "react";
import Form from "../Components/OnboardingComponents/Form";
import { mainOnboarding } from "../Styles/OnboardingPage/OnboardingStyles";
import { Onboarding } from "../interface/Onboarding";
import useGet from "../Hooks/useGet";
import DocumentList from "../Components/OnboardingComponents/DocumentList";
import Loader from "../Components/Loader";

import { useParams } from "react-router-dom";
import { Typography } from "antd";

export default function OnboardingPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState<Onboarding>();

  const { userId } = useParams();

  const { Title } = Typography;

  const id = localStorage.getItem("userId");

  const getData = (docs: Onboarding) => {
    if (docs) {
      setDocuments(docs);
      setIsLoading(false);
    } else {
      setDocuments(docs);
      setIsLoading(false);
    }
  };

  const getOnboardingData = useGet(`/onboarding/${userId}`, getData);

  useEffect(() => {
    setIsLoading(true);
    getOnboardingData();
  }, [getOnboardingData]);

  return (
    <div style={{ display: "flex", padding: "1rem" }}>
      <div style={{ padding: "2rem", flex: 1.5, height: "80vh" }}>
        {isLoading ? (
          <Loader loading={isLoading} message="Loading Documents" />
        ) : (
          <>
            {documents ? (
              <div>
                <h2>Submitted Documents</h2>
                <DocumentList data={documents} />
              </div>
            ) : (
              <div>
                {id === userId ? (
                  <>
                    <Title level={3}>Required Documents (*.pdf)</Title>
                    <div style={mainOnboarding}>
                      <Form isLoading={isLoading} setIsLoading={setIsLoading} />
                    </div>
                  </>
                ) : (
                  <Title level={3}>No Documents Uploaded</Title>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
