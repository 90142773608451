import React from "react";
import { colors } from "../Theme/Theme";

export const logInForm: React.CSSProperties = {
  flex: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const headingStyle: React.CSSProperties = {
  textAlign: "center",
  width: "100%",
  fontWeight: 600,
  color: colors.primary,
  fontSize: "32px",
};

export const layout: React.CSSProperties = {
  backgroundColor: "#1a1d26",
  flex: 1,
  color: "white",
  display: "flex",
  paddingLeft: "4rem",
  alignItems: "center",
  width: "100%",
};

export const buttonStyle: React.CSSProperties = {
  fontWeight: 600,
};

export const textBox: React.CSSProperties = {
  width: "14rem",
};

export const forgotPasswordLink: React.CSSProperties = {
  marginLeft: "15rem",
  marginTop: "-1.5rem",
  color: "#24A0ED",
};
