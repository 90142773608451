export const expenseHeader: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: "1rem",
  paddingLeft: "1rem",
};

export const expenseMain: React.CSSProperties = {
  flex: 1,
  paddingTop: "1rem",
  backgroundColor: "rgba(255,255,255,0.4)",
  height: "90vh",
};

export const expenseList: React.CSSProperties = {
  padding: "1rem",
  overflowY: "auto",
  height: "80vh",
};

export const expenseItem: React.CSSProperties = {
  border: "1px solid #b2d8d8",
  marginTop: "0.5rem",
  padding: "1rem",
  borderRadius: "5px",
  display: "flex",
  backgroundColor: "#e5f2f2",
};

export const itemHeading: React.CSSProperties = {
  fontSize: "14px",
  marginBottom: "0.5rem",
};

export const itemDetails: React.CSSProperties = {
  fontSize: "16px",
  fontWeight: "600",
};

export const itemSection: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingRight: "1rem",
};

export const commentSection: React.CSSProperties = {
  flex: 2.5,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
