import React from "react";
import { HiDotsVertical } from "react-icons/hi";
import {
  commentSection,
  expenseItem,
  itemDetails,
  itemHeading,
  itemSection,
} from "../../Styles/ExpensePage/ExpenseStyles";
import { MenuProps, Tag, notification } from "antd";
import { Dropdown, Space } from "antd";
import Expense from "../../interface/Expense";
import useFetch from "../../Hooks/useFetch";
import Link from "antd/es/typography/Link";
import { IoMdOpen } from "react-icons/io";
import { BASE_URL, fileType } from "../../constant";

const items: MenuProps["items"] = [
  {
    label: "Approve",
    key: "1",
  },
  {
    label: "Decline",
    key: "2",
  },
];

const employeeItems: MenuProps["items"] = [
  {
    label: "Delete",
    key: "0",
  },
];

function ExpenseItem({
  expense,
  access,
  status,
  setLoader,
  getExpenses,
}: {
  expense: Expense;
  access: string;
  status: string;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  getExpenses: () => void;
}) {
  const { call } = useFetch("DELETE");
  const { call: update } = useFetch("PUT");
  const dropdown = access === "self" ? employeeItems : items;

  const onClick: MenuProps["onClick"] = ({ key }) => {
    setLoader(true);
    if (key === "1") {
      const changedStatus = {
        id: `${expense.id}`,
        status: "approved",
      };
      update(
        `/expenses/status`,
        changedStatus,
        () => {
          notification.success({
            message: "Updated",
          });
          getExpenses();
        },
        (error) => {
          notification.warning({
            message: "Can't Update",
          });
        }
      );
    } else if (key === "0") {
      call(
        `/expenses/${expense.id}`,
        {},
        () => {
          notification.success({
            message: "Deleted",
          });
          getExpenses();
        },
        (error) => {
          notification.warning({
            message: "Can't Delete",
          });
        }
      );
    } else {
      const changedStatus = {
        id: `${expense.id}`,
        status: "cancelled",
      };
      update(
        `/expenses/status`,
        changedStatus,
        () => {
          notification.success({
            message: "Updated",
          });
          getExpenses();
        },
        (error) => {
          notification.warning({
            message: "Can't Update",
          });
        }
      );
    }
  };

  const token = localStorage.getItem("idToken");
  const handleClick = (fileName: String) => {
    setLoader(true);

    fetch(`${BASE_URL}/expenses/bill?path=${fileName}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      response.blob().then((blob) => {
        var b = new Blob([blob], { type: fileType });
        var url = window.URL.createObjectURL(b);

        setLoader(false);
        window.open(url);
      });
    });
  };

  return (
    <div style={expenseItem}>
      <div style={itemSection}>
        <div style={itemHeading}>Bill</div>
        <div style={itemDetails}>
          <Link
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => handleClick(expense.file)}
          >
            Open Bill &nbsp; <IoMdOpen size={20} />
          </Link>
        </div>
      </div>
      {access === "manage" && (
        <div style={itemSection}>
          <div style={itemHeading}>Employee</div>
          <div style={itemDetails}>{expense.user.name}</div>
        </div>
      )}
      <div style={itemSection}>
        <div style={itemHeading}>Type</div>
        <div style={itemDetails}>{expense.type}</div>
      </div>
      <div style={itemSection}>
        <div style={itemHeading}>Amount</div>
        <div style={itemDetails}>{expense.amount}</div>
      </div>
      <div style={itemSection}>
        <div style={itemHeading}>Status</div>
        {expense.status === "pending" && (
          <Tag
            style={{ width: "fit-content", fontWeight: 600 }}
            color="processing"
          >
            Pending
          </Tag>
        )}
        {expense.status === "approved" && (
          <Tag
            style={{ width: "fit-content", fontWeight: 600 }}
            color="success"
          >
            approved
          </Tag>
        )}
        {expense.status === "cancelled" && (
          <Tag style={{ width: "fit-content", fontWeight: 600 }} color="error">
            declined
          </Tag>
        )}
      </div>
      <div style={commentSection}>
        <div style={itemHeading}>Description</div>
        <div style={itemDetails}>{expense.description}</div>
      </div>
      {status === "pending" && (
        <Dropdown menu={{ items: dropdown, onClick }} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <HiDotsVertical style={{ cursor: "pointer" }} />
            </Space>
          </a>
        </Dropdown>
      )}
    </div>
  );
}

export default ExpenseItem;
