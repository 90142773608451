import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Form } from "antd";
import { ReactComponent as LoginIcon } from "../assets/login.svg";
import {
  headingStyle,
  buttonStyle,
  logInForm,
  layout,
} from "../Styles/LoginStyle";
import { innerContainerLogin } from "../Styles/HeaderStyle";
import { REDIRECT_URL } from "../constant";

export default function Login() {
  return (
    <div style={innerContainerLogin}>
      <div style={layout}>
        <p style={{ fontSize: "1.5rem", textAlign: "left" }}>
          Welcome to <br></br>
          <p style={{}}>
            <div className="wrapper four">
              <div className="type">
                <h3 className="typing">Studio Central</h3>
              </div>
            </div>
          </p>
        </p>
      </div>
      <div style={{ display: "flex", flex: 2 }}>
        <div style={logInForm}>
          <h2 style={headingStyle}>Login</h2>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            style={{ margin: "0" }}
          >
            <Form.Item>
              <Link to={REDIRECT_URL}>
                <Button type="primary" style={buttonStyle}>
                  LOGIN USING SLEEVESUP EMAIL
                </Button>
              </Link>
            </Form.Item>
          </Form>
        </div>
        <LoginIcon
          style={{
            height: "15rem",
            width: "10rem",
            flex: 2,
            marginTop: "15rem",
          }}
        />
      </div>
    </div>
  );
}
