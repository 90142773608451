import React from "react";
import {
  header,
  image,
  imageContainer,
  landingMain,
  authorText,
} from "../../Styles/ProfilePage/Landing";
import { ReactComponent as LandingGraphic } from "../../assets/landing_graphic.svg";
import User from "../../interface/User";
import { getGreeting, getRandomQuote } from "../../utils/AppHelper";

const Landing = ({ user }: { user: User | undefined }) => {
  const { quote, author } = getRandomQuote();
  return (
    <div style={landingMain}>
      <div style={header}>
        <h1>{getGreeting()}</h1>
        <h3>{quote}</h3>
        <h6 style={authorText}>- {author}</h6>
      </div>
      <div style={imageContainer}>{<LandingGraphic style={image} />}</div>
    </div>
  );
};

export default Landing;
