import React, { useState, useEffect } from "react";
import TableViewCard from "./TableViewCard";
import {
  filterButton,
  tableViewAllCards,
} from "../../Styles/Organization/Organization";
import useGet from "../../Hooks/useGet";
import { UserDetails } from "../../interface/User";
import Loader from "../../Components/Loader";
import { ReactComponent as Team } from "../../assets/undraw_in_the_office_re_jtgc.svg";
import FilterButton from "../../Components/Filters/Filter";
import { AutoComplete, Tag } from "antd";

export default function TableView() {
  const [usersList, setUsersList] = useState<UserDetails[]>([]);
  const [usersListFiltered, setUsersListFiltered] = useState<UserDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeCardId, setActiveCardId] = useState<string | null>(null);
  const [selectedMember, setSelectedMember] = useState("");
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [selectedDesignations, setSelectedDesignations] = useState<string[]>(
    []
  );
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [selectedSlabs, setSelectedSlabs] = useState<string[]>([]);

  const handleCardClick = (cardId: string) => {
    setActiveCardId(cardId);
  };

  const handleSidebarClose = () => {
    setActiveCardId(null);
  };

  const getData = (user: UserDetails[]) => {
    if (user) {
      setUsersList(user);
      setUsersListFiltered(user);
      setIsLoading(false);
    }
  };

  const getUsers = useGet(`/user/details`, getData);

  useEffect(() => {
    setIsLoading(true);
    getUsers();
  }, [getUsers]);

  const handleFilteredUsersSkillsChange = (filteredUsersSkills: string[]) => {
    const filteredUsers = usersList?.filter((user) =>
      filteredUsersSkills.includes(user.email)
    );
    setUsersListFiltered(filteredUsers);
  };

  const handleFilteredDesignationsChange = (filteredDesignations: string[]) => {
    const filteredUsers = usersList?.filter((user) =>
      filteredDesignations.includes(user.email)
    );
    setUsersListFiltered(filteredUsers);
  };
  const handleFilteredProjectsChange = (filteredProjects: string[]) => {
    const filteredUsers = usersList?.filter((user) =>
      filteredProjects.includes(user.email)
    );
    setUsersListFiltered(filteredUsers);
  };

  const handleFilteredSlabsChange = (filteredSlabs: string[]) => {
    const filteredUsers = usersList?.filter((user) =>
      filteredSlabs.includes(user.email)
    );
    setUsersListFiltered(filteredUsers);
  };

  const onSearch = (searchText: string) => {
    if (!searchText) {
      setOptions([]);
      setUsersListFiltered(usersList);
      return;
    }

    const filteredMembers = usersList?.filter((user) =>
      user.name.toLowerCase().startsWith(searchText.toLowerCase())
    );

    const formattedOptions = filteredMembers.map((user) => ({
      value: user.name,
    }));

    setOptions(formattedOptions);
    setUsersListFiltered(filteredMembers);
  };

  const onMemeberChange = (data: string) => {
    setSelectedMember(data);
  };

  return (
    <>
      <div>
        <div style={filterButton}>
          <AutoComplete
            value={selectedMember}
            options={options}
            className="search-user"
            showSearch
            style={{
              minWidth: 300,
              marginRight: "0.5rem",
            }}
            onSearch={onSearch}
            onChange={onMemeberChange}
            placeholder="Search in Studio SleevesUp"
          />
          <FilterButton
            userList={usersList}
            selectedDesignations={selectedDesignations}
            selectedSkills={selectedSkills}
            selectedProjects={selectedProjects}
            selectedSlabs={selectedSlabs}
            setSelectedDesignations={setSelectedDesignations}
            setSelectedSkills={setSelectedSkills}
            setSelectedProjects={setSelectedProjects}
            setSelectedSlabs={setSelectedSlabs}
            onFilteredUsersSkillsChange={handleFilteredUsersSkillsChange}
            onFilteredDesignationsChange={handleFilteredDesignationsChange}
            onFilteredProjectsChange={handleFilteredProjectsChange}
            onFilteredBillabilityChange={handleFilteredSlabsChange}
          />
        </div>
        <div
          style={{
            marginTop: "1rem",
            marginLeft: "0.5rem",
          }}
        >
          {selectedSkills &&
            selectedSkills.slice(0, 7).map((selected, key) => {
              return (
                <Tag
                  bordered={false}
                  color="cyan"
                  key={key}
                  style={{ marginRight: "0.5rem" }}
                >
                  {selected}
                </Tag>
              );
            })}
          {selectedDesignations &&
            selectedDesignations.slice(0, 7).map((selected, key) => {
              return (
                <Tag
                  bordered={false}
                  color="cyan"
                  key={key}
                  style={{ marginRight: "0.5rem" }}
                >
                  {selected}
                </Tag>
              );
            })}
          {selectedProjects &&
            selectedProjects.slice(0, 7).map((selected, key) => {
              return (
                <Tag
                  bordered={false}
                  color="cyan"
                  key={key}
                  style={{ marginRight: "0.5rem" }}
                >
                  {selected}
                </Tag>
              );
            })}
          {selectedSlabs &&
            selectedSlabs.slice(0, 7).map((selected, key) => {
              return (
                <Tag
                  bordered={false}
                  color="cyan"
                  key={key}
                  style={{ marginRight: "0.5rem" }}
                >
                  {selected}
                </Tag>
              );
            })}
        </div>
      </div>
      {isLoading ? (
        <Loader loading={isLoading} message="Loading Employees..." />
      ) : (
        <div style={{ display: "flex" }}>
          <div style={tableViewAllCards}>
            {usersListFiltered
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((data, index) => {
                const isCardActive = activeCardId === data.email;
                return (
                  <TableViewCard
                    key={index}
                    data={data}
                    userList={usersList.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    )}
                    isActive={isCardActive}
                    onCardClick={() => handleCardClick(data.email)}
                    onClose={handleSidebarClose}
                  />
                );
              })}
          </div>
          <Team style={{ flex: 1, height: "20rem", paddingRight: "2rem" }} />
        </div>
      )}
    </>
  );
}
