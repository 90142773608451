import React from "react";
import { profileMain, editButtonStyle } from "../../Styles/ProfilePage/Profile";
import { Button, Space } from "antd";
import { useParams } from "react-router-dom";

export const ProfileHeader = ({
  isEditable,
  setIsEditable,
}: {
  isEditable: boolean;
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const loggedInUser = localStorage.getItem("userId");

  const { userId } = useParams();

  return (
    <div
      style={{
        ...profileMain,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <div>
        <Space wrap>
          {!isEditable && userId === loggedInUser && (
            <Button
              type="primary"
              style={editButtonStyle}
              onClick={() => setIsEditable(true)}
            >
              EDIT
            </Button>
          )}
        </Space>
      </div>
    </div>
  );
};
