import { Button } from "antd";
import React from "react";
import { currentPathButtonStyle } from "../../Styles/ProfilePage/Profile";

interface ProfileNavbarProps {
  setPath: (path: string) => void;
  currentPath: string;
}

const ProfileNavbar: React.FC<ProfileNavbarProps> = ({
  setPath,
  currentPath,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "4rem",
        height: "40vh",
        borderRight: "1px solid gray",
        marginLeft: "2rem",
        marginRight: "1rem",
      }}
    >
      <Button
        type={currentPath === "personal" ? "primary" : "default"}
        style={currentPathButtonStyle}
        onClick={() => setPath("personal")}
      >
        Personal Details
      </Button>
      <Button
        type={currentPath === "accountDetails" ? "primary" : "default"}
        style={currentPathButtonStyle}
        onClick={() => setPath("accountDetails")}
      >
        Account Details
      </Button>
      <Button
        type={currentPath === "address" ? "primary" : "default"}
        style={currentPathButtonStyle}
        onClick={() => setPath("address")}
      >
        Address
      </Button>
      <Button
        type={currentPath === "skills" ? "primary" : "default"}
        style={currentPathButtonStyle}
        onClick={() => setPath("skills")}
      >
        Skills
      </Button>
      <Button
        type={currentPath === "onboarding" ? "primary" : "default"}
        style={currentPathButtonStyle}
        onClick={() => setPath("onboarding")}
      >
        Onboarding
      </Button>
    </div>
  );
};

export default ProfileNavbar;
