import React from "react";
import { Space, Spin } from "antd";

const Loader = ({
  loading,
  message,
}: {
  loading: boolean;
  message: string;
}) => {
  return loading ? (
    <Space
      size="middle"
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Spin size="large" style={{ color: "#1a1d26" }} />
      <h3>{message}</h3>
    </Space>
  ) : null;
};

export default Loader;
