import React from "react";
import { colors } from "../Theme/Theme";

export const videoSection: React.CSSProperties = {
  marginLeft: "2rem",
  marginTop: "1rem",
  width: "55rem",
  height: "15rem",
  border: ".1rem solid #444",
  background: colors.innerBackground,
  borderRadius: "1rem",
  overflowX: "auto",
};

export const heading: React.CSSProperties = {
  fontSize: "1.5rem",
  paddingLeft: "1rem",
  paddingTop: "1rem",
};

export const linkStyle: React.CSSProperties = {
  display: "flex",
  marginLeft: "0rem",
  paddingLeft: "0.5rem",
  marginRight: "1rem",
  width: "18rem",
  height: "8rem",
  marginBottom: "1rem",
  background: "#e5f2f2",
  border: "1px solid #cce5e5",
  borderRadius: "1rem",
};

export const pdfStyling: React.CSSProperties = {
  marginLeft: "2rem",
  paddingLeft: "1rem",
  paddingTop: "1rem",
  marginRight: "1rem",
  width: "40rem",
  display: "flex",
};

export const courseAuthor: React.CSSProperties = {
  display: "flex",
  fontSize: "0.5rem",
  padding: "0.15rem",
};

export const courseNameStyling: React.CSSProperties = {
  fontSize: "1.2rem",
  paddingTop: ".5rem",
  fontWeight: 600,
  color: "#004c4c",
};

export const courseDuration: React.CSSProperties = {
  color: "#e5de00",
  padding: ".5rem",
  borderRadius: "1rem",
};

export const courseLayoutStyle: React.CSSProperties = {
  paddingTop: "1rem",
  paddingBottom: "1rem",
  paddingLeft: "1rem",
  display: "flex",
  maxHeight: "30rem",
  overflowY: "auto",
  flexDirection: "row",
  flexWrap: "wrap",
  flex: 3,
};

export const mainVideoCardStyle: React.CSSProperties = {
  width: "21rem",
  marginLeft: "1.3rem",
  paddingTop: "1rem",
  marginBottom: "1rem",
  marginTop: "1rem",
  background: colors.innerBackground,
};

export const videoCardStyling: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "normal",
  flexWrap: "wrap",
  width: "55rem",
  height: "28rem",
  background: colors.innerBackground,
  overflowY: "auto",
  marginLeft: "3%",
};

export const wholeContainer: React.CSSProperties = {
  height: "90vh",
  overflowY: "auto",
};

export const pdfBox: React.CSSProperties = {
  height: "25rem",
  marginLeft: "2rem",
  background: colors.innerBackground,
  overflowY: "auto",
  width: "50%",
  padding: "1rem",
};

export const pdfIcon: React.CSSProperties = {
  height: "25rem",
  width: "25rem",
  marginLeft: "2rem",
  padding: "0",
};

export const courseIcon: React.CSSProperties = {
  height: "20rem",
  flex: 1,
};
