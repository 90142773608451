import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { useLocation, useNavigate } from "react-router-dom";
import User from "../interface/User";
import { BASE_URL } from "../constant";
import { notification } from "antd";
import jwtDecode from "jwt-decode";

function getClaimsFromToken(token: string): { [key: string]: any } | null {
  try {
    const decoded = jwtDecode(token);
    return decoded as { [key: string]: any } | null;
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
}

const setDataItem = async (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

const LoadingUser = () => {
  const loading: boolean = true;

  const [tokenData, setTokenData] = useState<string | null>(null);

  const navigate = useNavigate();

  const setUserData = (data: User) => {
    if (tokenData) {
      const claims = getClaimsFromToken(tokenData);
      if (data && claims) {
        localStorage.setItem("role", claims["cognito:groups"][0]);
        localStorage.setItem("userId", data.id.toString());
        navigate("/");
      }
    }
  };

  const logInUser = (apiPath: string, token: string) => {
    try {
      fetch(`${BASE_URL}${apiPath}`, {
        method: "GET",
        headers: token
          ? {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            }
          : {},
      })
        .then((res) => {
          if (res.status === 401) navigate("/login");
          if (res.status === 403) navigate("/login");
          if (res.status === 307) {
            notification.info({
              message: "Account created , please login again!!",
            });
            navigate("/login");
          }
          return res.json();
        })
        .then((data) => {
          setUserData(data.data);
        })
        .catch((e) => {});
    } catch (error) {}
  };

  const url = useLocation().hash.replace("#", "?");
  useEffect(() => {
    async function setToken() {
      if (new URLSearchParams(url).get("error_description")) {
        notification.error({
          message: "Something went wrong.",
        });
        navigate("/login");
      }

      var access_token = new URLSearchParams(url).get("access_token");
      setTokenData(new URLSearchParams(url).get("id_token"));

      if (access_token !== null) {
        setDataItem("accessToken", access_token);
      }
      if (tokenData !== null) {
        setDataItem("idToken", tokenData);
        logInUser("/user/log-in", tokenData);
      }
    }
    setToken();
  }, [tokenData]);

  return (
    <div>
      <Loader loading={loading} message="Logging in User" />
    </div>
  );
};

export default LoadingUser;
