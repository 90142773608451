import jwtDecode, { JwtPayload } from "jwt-decode";
import React from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { userRoles } from "../enums/Roles";
function ProtectedRoutes() {
  const path = useLocation();
  const isTokenValid = (): boolean => {
    const userToken = localStorage.getItem("idToken");
    try {
      if (userToken) {
        const decodedToken = jwtDecode<JwtPayload>(userToken);
        if (decodedToken && decodedToken.exp) {
          let exp = decodedToken.exp;
          if (exp < (new Date().getTime() + 1) / 1000) {
            localStorage.clear();
            return false;
          }
          return true;
        }
        return false;
      }
    } catch (e: any) {
      localStorage.clear();
      return false;
    }
    return false;
  };
  const { userId } = useParams();
  const isUserAllowed = () => {
    const userRole = localStorage.getItem("role");
    const id = localStorage.getItem("userId");
    if (id !== userId) {
      if (
        userRole !== userRoles.HR &&
        userRole !== userRoles.Admin &&
        (path.pathname.includes("profile") || path.pathname.includes("report"))
      ) {
        return false;
      }
    }
    return true;
  };
  if (!isTokenValid()) {
    return <Navigate to={{ pathname: "/login" }} />;
  }
  if (!isUserAllowed()) {
    return <Navigate to={{ pathname: "/session-error" }} />;
  }
  return <Outlet />;
}
export default ProtectedRoutes;
