export const container: React.CSSProperties = {
  display: "flex",
};

export const card: React.CSSProperties = {
  height: "1rem",
  padding: "1rem",
  paddingTop: "1.5rem",
  alignItems: "center",
  alignContent: "center",
};

export const projectDecription: React.CSSProperties = {
  marginLeft: "1rem",
  marginBottom: "0rem",
};

export const changeButton: React.CSSProperties = {
  width: "5rem",
  height: "2rem",
};

export const userCard: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
};

export const addEmployeeName: React.CSSProperties = {
  width: "100%",
  marginTop: "0.5rem",
  marginBottom: "1rem",
};

export const managerNameBox: React.CSSProperties = {
  alignItems: "center",
};
