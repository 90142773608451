export const mainContainer: React.CSSProperties = {
  padding: "1rem",
  height: "90vh",
  overflowY: "auto",
};

export const headerInput: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const dateInput: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "35%",
  textAlign: "center",
};
