export const mainModal: React.CSSProperties = {
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  background: "rgb(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const uploadButton: React.CSSProperties = {
  width: "100%",
  backgroundColor: "red",
};

export const inputStyle: React.CSSProperties = {
  marginTop: "1rem",
};
