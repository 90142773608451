import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  FloatButton,
  Upload,
  UploadFile,
  UploadProps,
  message,
  notification,
} from "antd";
import React, { useState } from "react";
import {
  floatButtonStyles,
  form,
} from "../../Styles/OnboardingPage/OnboardingStyles";
import { RcFile } from "antd/es/upload";
import Loader from "../Loader";
import { BASE_URL, fileSize, fileType } from "../../constant";
import { colors } from "../../Theme/Theme";

function Form({
  isLoading,
  setIsLoading,
}: {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [panCard, setPanCard] = useState<UploadFile[]>([]);
  const [aadharCard, setAadharCard] = useState<UploadFile[]>([]);
  const [sscMarksheet, setSscMarksheet] = useState<UploadFile[]>([]);
  const [hscMarksheet, setHscMarksheet] = useState<UploadFile[]>([]);
  const [collegeMarksheet, setCollegeMarksheet] = useState<UploadFile[]>([]);
  const [pfForm, setPfForm] = useState<UploadFile[]>([]);
  const [voluntaryForm, setVoluntaryForm] = useState<UploadFile[]>([]);

  const props: UploadProps = {
    onDrop(e) {
      message.error("Dropped files.");
    },
  };

  const handleOk = () => {
    setIsLoading(true);
    const token = localStorage.getItem("idToken");
    const formData = new FormData();
    panCard.forEach((file) => {
      formData.append("panCard", file as RcFile);
    });

    aadharCard.forEach((file) => {
      formData.append("aadharCard", file as RcFile);
    });

    if (sscMarksheet.length > 0) {
      sscMarksheet.forEach((file) => {
        formData.append("sscMarksheet", file as RcFile);
      });
    }

    if (hscMarksheet.length > 0) {
      hscMarksheet.forEach((file) => {
        formData.append("hscMarksheet", file as RcFile);
      });
    }

    if (collegeMarksheet.length > 0) {
      collegeMarksheet.forEach((file) => {
        formData.append("collegeMarksheet", file as RcFile);
      });
    }

    pfForm.forEach((file) => {
      formData.append("pfForm", file as RcFile);
    });

    if (voluntaryForm.length > 0) {
      voluntaryForm.forEach((file) => {
        formData.append("voluntaryPfForm", file as RcFile);
      });
    }

    const userId = localStorage.getItem("userId");
    fetch(`${BASE_URL}/onboarding/${userId}`, {
      method: "POST",
      body: formData,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setIsLoading(false);
          setAadharCard([]);
          setCollegeMarksheet([]);
          setHscMarksheet([]);
          setPanCard([]);
          setSscMarksheet([]);
          setPfForm([]);
          setVoluntaryForm([]);
          message.success("upload successfully.");
          window.location.reload();
        } else {
          setIsLoading(false);
          message.success("User Exists");
        }
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {});
  };

  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} message="Uploading Documents" />
      ) : (
        <div style={form}>
          <Divider orientation="left">
            <div
              style={{ color: colors.primary, fontWeight: 600, fontSize: 18 }}
            >
              Mandatory Documents
            </div>
          </Divider>
          <div style={{ display: "flex", paddingTop: "1rem" }}>
            <h3 style={{ display: "flex", width: "15rem" }}>
              Pan Card -{" "}
              <p
                style={{
                  color: "red",
                  marginLeft: "0.5rem",
                  marginRight: "1rem",
                }}
              >
                {" "}
                *
              </p>
            </h3>
            <Upload
              {...props}
              onRemove={(file) => {
                const index = panCard.indexOf(file);
                const newFileList = panCard.slice();
                newFileList.splice(index, 1);
                setPanCard(newFileList);
              }}
              beforeUpload={(file) => {
                if (file.type === fileType) {
                  if (file.size < fileSize) {
                    setPanCard([...panCard, file]);
                  } else {
                    notification.error({
                      message: "File size limit exceeded.",
                    });
                  }
                } else {
                  notification.error({ message: "Wrong File type" });
                }
                return false;
              }}
              fileList={panCard}
            >
              <Button
                style={{
                  fontWeight: 600,
                  color: colors.primary,
                  borderColor: "#cce5e5",
                }}
                icon={<UploadOutlined />}
              >
                Upload
              </Button>
            </Upload>
          </div>
          <div style={{ display: "flex", paddingTop: "1rem" }}>
            <h3 style={{ display: "flex", width: "15rem" }}>
              Aadhar Card -{" "}
              <p
                style={{
                  color: "red",
                  marginLeft: "0.5rem",
                  marginRight: "1rem",
                }}
              >
                {" "}
                *
              </p>
            </h3>
            <Upload
              {...props}
              onRemove={(file) => {
                const index = aadharCard.indexOf(file);
                const newFileList = aadharCard.slice();
                newFileList.splice(index, 1);
                setAadharCard(newFileList);
              }}
              beforeUpload={(file) => {
                if (file.type === fileType) {
                  if (file.size < fileSize) {
                    setAadharCard([...aadharCard, file]);
                  } else {
                    notification.error({
                      message: "File size limit exceeded.",
                    });
                  }
                } else {
                  notification.error({ message: "Wrong File type" });
                }
                return false;
              }}
              fileList={aadharCard}
            >
              <Button
                style={{
                  fontWeight: 600,
                  color: colors.primary,
                  borderColor: "#cce5e5",
                }}
                icon={<UploadOutlined />}
              >
                Upload
              </Button>
            </Upload>
          </div>
          <div style={{ display: "flex", paddingTop: "1rem" }}>
            <h3 style={{ display: "flex", width: "15rem" }}>
              PF form -{" "}
              <p
                style={{
                  color: "red",
                  marginLeft: "0.5rem",
                  marginRight: "1rem",
                }}
              >
                {" "}
                *
              </p>
            </h3>
            <Upload
              {...props}
              onRemove={(file) => {
                const index = pfForm.indexOf(file);
                const newFileList = pfForm.slice();
                newFileList.splice(index, 1);
                setPfForm(newFileList);
              }}
              beforeUpload={(file) => {
                if (file.type === fileType) {
                  if (file.size < fileSize) {
                    setPfForm([...pfForm, file]);
                  } else {
                    notification.error({
                      message: "File size limit exceeded.",
                    });
                  }
                } else {
                  notification.error({ message: "Wrong File type" });
                }
                return false;
              }}
              fileList={pfForm}
            >
              <Button
                style={{
                  fontWeight: 600,
                  color: colors.primary,
                  borderColor: "#cce5e5",
                }}
                icon={<UploadOutlined />}
              >
                Upload
              </Button>
            </Upload>
          </div>
          <Divider style={{ color: colors.primary }} orientation="left">
            <div
              style={{ color: colors.primary, fontWeight: 600, fontSize: 18 }}
            >
              Optional Documents
            </div>
          </Divider>
          <div style={{ display: "flex", marginTop: "2rem" }}>
            <h3 style={{ display: "flex", width: "15rem" }}>
              10th Marksheet -
            </h3>
            <Upload
              {...props}
              onRemove={(file) => {
                const index = sscMarksheet.indexOf(file);
                const newFileList = sscMarksheet.slice();
                newFileList.splice(index, 1);
                setSscMarksheet(newFileList);
              }}
              beforeUpload={(file) => {
                if (file.type === fileType) {
                  if (file.size < fileSize) {
                    setSscMarksheet([...sscMarksheet, file]);
                  } else {
                    notification.error({
                      message: "File size limit exceeded.",
                    });
                  }
                } else {
                  notification.error({ message: "Wrong File type" });
                }
                return false;
              }}
              fileList={sscMarksheet}
            >
              <Button
                style={{
                  fontWeight: 600,
                  color: colors.primary,
                  borderColor: "#cce5e5",
                }}
                icon={<UploadOutlined />}
              >
                Upload
              </Button>
            </Upload>
          </div>
          <div style={{ display: "flex", marginTop: "2rem" }}>
            <h3 style={{ display: "flex", width: "15rem" }}>
              12th Marksheet -
            </h3>
            <Upload
              {...props}
              onRemove={(file) => {
                const index = hscMarksheet.indexOf(file);
                const newFileList = hscMarksheet.slice();
                newFileList.splice(index, 1);
                setHscMarksheet(newFileList);
              }}
              beforeUpload={(file) => {
                if (file.type === fileType) {
                  if (file.size < fileSize) {
                    setHscMarksheet([...hscMarksheet, file]);
                  } else {
                    notification.error({
                      message: "File size limit exceeded.",
                    });
                  }
                } else {
                  notification.error({ message: "Wrong File type" });
                }
                return false;
              }}
              fileList={hscMarksheet}
            >
              <Button
                style={{
                  fontWeight: 600,
                  color: colors.primary,
                  borderColor: "#cce5e5",
                }}
                icon={<UploadOutlined />}
              >
                Upload
              </Button>
            </Upload>
          </div>
          <div style={{ display: "flex", marginTop: "2rem" }}>
            <h3 style={{ display: "flex", width: "15rem" }}>
              University Marksheet -
            </h3>
            <Upload
              {...props}
              onRemove={(file) => {
                const index = collegeMarksheet.indexOf(file);
                const newFileList = collegeMarksheet.slice();
                newFileList.splice(index, 1);
                setCollegeMarksheet(newFileList);
              }}
              beforeUpload={(file) => {
                if (file.type === fileType) {
                  if (file.size < fileSize) {
                    setCollegeMarksheet([...collegeMarksheet, file]);
                  } else {
                    notification.error({
                      message: "File size limit exceeded.",
                    });
                  }
                } else {
                  notification.error({ message: "Wrong File type" });
                }
                return false;
              }}
              fileList={collegeMarksheet}
            >
              <Button
                style={{
                  fontWeight: 600,
                  color: colors.primary,
                  borderColor: "#cce5e5",
                }}
                icon={<UploadOutlined />}
              >
                Upload
              </Button>
            </Upload>
          </div>
          <div style={{ display: "flex", marginTop: "2rem" }}>
            <h3 style={{ display: "flex", width: "15rem" }}>
              Voluntary PF form -
            </h3>
            <Upload
              {...props}
              onRemove={(file) => {
                const index = voluntaryForm.indexOf(file);
                const newFileList = voluntaryForm.slice();
                newFileList.splice(index, 1);
                setVoluntaryForm(newFileList);
              }}
              beforeUpload={(file) => {
                if (file.type === fileType) {
                  if (file.size < fileSize) {
                    setVoluntaryForm([...voluntaryForm, file]);
                  } else {
                    notification.error({
                      message: "File size limit exceeded.",
                    });
                  }
                } else {
                  notification.error({ message: "Wrong File type" });
                }
                return false;
              }}
              fileList={voluntaryForm}
            >
              <Button
                style={{
                  fontWeight: 600,
                  color: colors.primary,
                  borderColor: "#cce5e5",
                }}
                icon={<UploadOutlined />}
              >
                Upload
              </Button>
            </Upload>
          </div>
          {/* {panCard.length > 0 && aadharCard.length > 0 && pfForm.length > 0 && ( */}
          <FloatButton
            type={
              panCard.length > 0 && aadharCard.length > 0 && pfForm.length > 0
                ? "primary"
                : "default"
            }
            description="Save"
            shape="square"
            style={
              panCard.length > 0 && aadharCard.length > 0 && pfForm.length > 0
                ? floatButtonStyles
                : {
                    ...floatButtonStyles,
                    pointerEvents: "none",
                    cursor: "not-allowed",
                  }
            }
            onClick={handleOk}
          />
          {/* )} */}
        </div>
      )}
    </>
  );
}

export default Form;
