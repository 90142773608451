import React, { useEffect, useState } from "react";
import Profile from "../Components/ProfileComponents/Profile";
import useGet from "../Hooks/useGet";
import { profilePageMain } from "../Styles/ProfilePage/ProfilePage";
import User from "../interface/User";
import Loader from "../Components/Loader";
import { useParams } from "react-router-dom";

const ProfilePage = () => {
  const { userId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const fixedDetailsurl = `/user/${userId}`;

  const getFixedUserDetails = useGet(fixedDetailsurl, getFixedUserDetailsData);

  function getFixedUserDetailsData(data: User) {
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    getFixedUserDetails();
  }, [getFixedUserDetails]);

  return (
    <div
      style={{
        ...profilePageMain,
      }}
    >
      {isLoading ? (
        <Loader loading={isLoading} message="Loading Profile" />
      ) : (
        <Profile />
      )}
    </div>
  );
};

export default ProfilePage;
