export let BASE_URL: string;
export let REDIRECT_URL: string;

if (process.env.REACT_APP_BASE_URL) {
  BASE_URL = process.env.REACT_APP_BASE_URL;
} else {
  BASE_URL = "https://studio-service.central.sleevesup.com.au/api/v1";
}

if (process.env.REACT_APP_REDIRECT_URL) {
  REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
} else {
  REDIRECT_URL =
    "https://studio-centra-app.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=677hdmd3a8eg9767a0s0s9399d&response_type=token&scope=openid&redirect_uri=https%3A%2F%2Fstudio.central.sleevesup.com.au%2Fuser-login";
}

export const dateFormatList = [
  "DD/MM/YYYY",
  "DD/MM/YY",
  "DD-MM-YYYY",
  "DD-MM-YY",
];
export const regex = /^-?\d*(\.\d*)?$/;

export const greytHrIdRegex = /^SS[0-9]{4}$/;

export const fileSize = 5000000;

export const fileType = "application/pdf";
