import React, { useState } from "react";
import AccountDetails from "./AccountDetails";
import ProfileNavbar from "./ProfileNavbar";
import OnboardingPage from "../../Pages/OnboardingPage";
import AddressDetails from "./AddressDetails";
import PersonalInformationDetails from "./PersonalInformationDetails";
import SkillDetails from "./SkillDetails";

const Profile = () => {
  const [path, setPath] = useState("personal");

  return (
    <div style={{ display: "flex" }}>
      <ProfileNavbar setPath={setPath} currentPath={path} />
      {path === "personal" && <PersonalInformationDetails />}
      {path === "accountDetails" && <AccountDetails />}
      {path === "onboarding" && <OnboardingPage />}
      {path === "address" && <AddressDetails />}
      {path === "skills" && <SkillDetails />}
    </div>
  );
};

export default Profile;
