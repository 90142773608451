import React, { useState } from "react";
import {
  AutoComplete,
  Button,
  Collapse,
  Input,
  Modal,
  Select,
  notification,
} from "antd";
import { AllProject } from "../../interface/AllProject";
import UserCard from "./UserCard";
import { FilterData } from "../../interface/FilterData";
import User from "../../interface/User";
import ProjectUserCard from "./ProjectUserCard";
import useFetch from "../../Hooks/useFetch";
import {
  addEmployeeName,
  card,
  container,
  managerNameBox,
  projectDecription,
} from "../../Styles/Project/ProjectListStyles";
import { Typography } from "antd";
import { userRoles } from "../../enums/Roles";
const { Text, Paragraph } = Typography;

const { Option } = Select;
const { Panel } = Collapse;

const ProjectList = ({
  data,
  userList,
  filterData,
  getAllProjectData,
}: {
  data: AllProject;
  userList: User[];
  filterData: FilterData[];
  getAllProjectData: () => void;
}) => {
  const userType = localStorage.getItem("role");

  const [userName, setUserName] = useState("");
  const [availability, setAvailability] = useState<number>(0);
  const [billable, setBillable] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { call } = useFetch("POST");

  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  const selectUserName = (value: string) => setUserName(value);
  const handleChange = (newValue: boolean) => setBillable(newValue);

  const addUserHandler = () => {
    setIsModalOpen(true);
  };

  const submitHandler = () => {
    const selectedUserData = userList.filter((user) => user.name === userName);
    const newUserData = {
      userId: selectedUserData[0].id,
      projectId: data.id,
      availability: availability,
      billable: billable,
    };

    if (availability > 100) {
      notification.error({ message: "Allocation can't be more than 100%" });
      getAllProjectData();
      return;
    }
    if (availability === 0) {
      notification.error({ message: "Allocation can't be nil or 0%" });
      getAllProjectData();
      return;
    }

    call(
      "/project/add-user",
      newUserData,
      (data) => {
        notification.success({
          message: "User Added successfully",
        });
        getAllProjectData();
      },
      (error) => {
        if (error.message === "Already Found") {
          notification.warning({
            message: "User is already allocated to this project.",
          });
        } else if (error.message === "Availability exceeded than 100") {
          notification.warning({
            message: "Allocation is exceeding 100% for the user",
          });
        } else {
          notification.warning({
            message: "Failed to add the project",
          });
        }
      }
    );
    setIsModalOpen(false);
  };

  return (
    <div style={{ marginTop: "1.2rem", width: "100%" }}>
      <Collapse accordion size="large">
        <Panel header={data.name} key={data.id}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
              paddingRight: "1rem",
            }}
          >
            <div>
              <div style={container}>
                <Text style={{ marginLeft: "1rem" }} strong={true}>
                  Project Description:{" "}
                </Text>
                <Paragraph style={projectDecription}>
                  {data.description}
                </Paragraph>
              </div>
              <div style={{ ...card, ...container }}>
                <div
                  style={{
                    ...container,
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text strong={true}>Project Lead:</Text>
                  <UserCard
                    data={data.leadName}
                    projectId={data.id}
                    userList={userList}
                    filterData={filterData}
                    type="lead"
                    getAllProjectData={getAllProjectData}
                  />
                </div>
              </div>

              <div style={{ ...card, ...container }}>
                <div
                  style={{
                    ...container,
                    ...managerNameBox,
                    width: "100%",
                  }}
                >
                  <Text strong={true}>Project Manager: </Text>
                  <UserCard
                    data={data.managerName}
                    projectId={data.id}
                    userList={userList}
                    filterData={filterData}
                    type="manager"
                    getAllProjectData={getAllProjectData}
                  />
                </div>
              </div>
            </div>

            {(userType === userRoles.Admin ||
              userType === userRoles.Allocation) && (
              <Button
                style={{ fontWeight: "bold" }}
                key="submit"
                type="primary"
                onClick={addUserHandler}
              >
                Add User
              </Button>
            )}
          </div>
          <br />

          {data.userList.length > 0 ? (
            data.userList.map((userData, index) => {
              return (
                <ProjectUserCard
                  userData={userData}
                  key={index}
                  getAllProjectData={getAllProjectData}
                  id={data.id}
                  userList={userList}
                />
              );
            })
          ) : (
            <h3>No Members Working in the project</h3>
          )}
          <br />
          {isModalOpen && (
            <Modal
              style={{ top: "50px" }}
              title="Add New Member"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={
                <Button
                  key="submit"
                  type="primary"
                  onClick={submitHandler}
                  style={{ marginTop: "1rem" }}
                >
                  Save
                </Button>
              }
            >
              <AutoComplete
                style={addEmployeeName}
                className="search-user"
                placeholder="Employee Name"
                options={filterData}
                filterOption={true}
                onSelect={selectUserName}
              />
              <Input
                placeholder="Allocation Percentage"
                type="number"
                style={{ marginBottom: "1rem" }}
                onChange={(e) => setAvailability(parseInt(e.target.value))}
              />
              <div style={container}>
                <h3 style={{ marginRight: "2rem" }}>Billable</h3>
                <Select
                  value={billable}
                  onChange={handleChange}
                  className="search-user"
                  style={{ width: "5rem" }}
                >
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </div>
            </Modal>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default ProjectList;
