import React, { useEffect, useState } from "react";
import ExpenseList, {
  ExpenseResponse,
} from "../Components/ExpenseComponents/ExpenseList";
import {
  MenuProps,
  Menu,
  Button,
  message,
  Upload,
  Modal,
  Input,
  notification,
} from "antd";
import {
  expenseHeader,
  expenseMain,
} from "../Styles/ExpensePage/ExpenseStyles";
import { InboxOutlined } from "@ant-design/icons";
import { inputStyle } from "../Styles/ExpensePage/ExpenseModalStyles";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import Loader from "../Components/Loader";
import SelfExpense from "../Components/ExpenseComponents/SelfExpense";
import { BASE_URL, fileSize, fileType, regex } from "../constant";
import { userRoles } from "../enums/Roles";
import Expense from "../interface/Expense";
import useGet from "../Hooks/useGet";

const { Dragger } = Upload;

const { TextArea } = Input;

function ExpensesPage(this: any) {
  const [isLoading, setIsLoading] = useState(false);

  const [current, setCurrent] = useState("self");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [comment, setComment] = useState("");
  const [billType, setBillType] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const role = localStorage.getItem("role");

  const userId = localStorage.getItem("userId");
  const [expensesList, setExpensesList] = useState<Expense[]>([]);
  const [requestedList, setRequestedList] = useState<Expense[]>([]);

  const getData = (expense: ExpenseResponse) => {
    if (expense) {
      setExpensesList(expense.self);
      setRequestedList(expense.memberList);
    }
    setIsLoading(false);
  };

  const getExpenses = useGet(`/expenses/${userId}`, getData);

  useEffect(() => {
    getExpenses();
  }, [getExpenses]);

  const items: MenuProps["items"] =
    role === userRoles.HR || role === userRoles.Admin
      ? [
          {
            label: "My requests",
            key: "self",
          },
          {
            label: "Requested",
            key: "requested",
          },
        ]
      : [
          {
            label: "My requests",
            key: "self",
          },
        ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsLoading(true);
    const token = localStorage.getItem("idToken");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });
    formData.append("billType", billType);
    formData.append("amount", amount);
    formData.append("description", comment);
    formData.append("userId", localStorage.getItem("userId")!!);
    fetch(`${BASE_URL}/expenses`, {
      method: "POST",
      body: formData,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then(() => {
        setIsLoading(false);
        setFileList([]);
        setAmount("");
        setBillType("");
        setComment("");
        message.success("uploaded successfully.");
        setIsModalOpen(false);
        getExpenses();
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {});
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = e.target.value;
    const reg = regex;
    if (reg.test(String(inputValue)) || inputValue === "0") {
      setAmount(inputValue);
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComment(e.target.value);
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (file.type === fileType) {
        if (file.size < fileSize) {
          setFileList([...fileList, file]);
        } else {
          notification.error({ message: "File size limit exceeded." });
        }
      } else {
        notification.error({ message: "Wrong File type." });
      }
      return false;
    },
    fileList,
    onDrop(e) {},
  };

  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} message="Fetching your Requests" />
      ) : (
        <div style={expenseMain}>
          <div style={expenseHeader}>
            <Menu
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
              style={{
                background: "none",
                width: "70%",
                border: "none",
                fontWeight: 600,
                fontSize: 20,
                marginBottom: "1rem",
              }}
            />
            <Button
              style={{ fontWeight: 600, width: "12rem" }}
              type="primary"
              className="button"
              onClick={showModal}
            >
              Request Reimbursement
            </Button>
          </div>
          <br />
          <hr />
          {current === "self" ? (
            <SelfExpense
              expensesList={expensesList}
              requestedList={requestedList}
              getExpenses={getExpenses}
              setIsLoading={setIsLoading}
            />
          ) : (
            <ExpenseList
              current={current}
              expensesList={expensesList}
              requestedList={requestedList}
              getExpenses={getExpenses}
              setLoading={setIsLoading}
            />
          )}
          <Modal
            className="ant-model-content"
            style={{ top: 20 }}
            title="Request Reimbursement"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
              <Button
                key="submit"
                type="primary"
                onClick={handleOk}
                disabled={fileList.length === 0}
              >
                Save
              </Button>
            }
          >
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag Bill to be uploaded
              </p>
              <p className="ant-upload-hint">(*.pdf file upto 5mb)</p>
            </Dragger>
            <Input
              placeholder="Bill Type"
              style={inputStyle}
              onChange={(e) => setBillType(e.target.value)}
              value={billType}
            />
            <Input
              style={inputStyle}
              onChange={handleChange}
              placeholder="Input amount"
              maxLength={16}
              value={amount}
            />
            <TextArea
              style={inputStyle}
              placeholder="Description about the expense."
              allowClear
              onChange={onChange}
              value={comment}
            />
          </Modal>
        </div>
      )}
    </>
  );
}

export default ExpensesPage;
