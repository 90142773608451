import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import useFetch from "../../Hooks/useFetch";

import {
  Button,
  DatePicker,
  DatePickerProps,
  Drawer,
  Dropdown,
  FloatButton,
  List,
  MenuProps,
  Modal,
  Select,
  Space,
  Typography,
  UploadFile,
  message,
  notification,
} from "antd";
import {
  DownOutlined,
  FileAddOutlined,
  InboxOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  colorBox,
  leaveHeader,
  legend,
  legendList,
  legendText,
} from "../../Styles/LeavesCalendar/CalendarStyles";
import { useNavigate, useParams } from "react-router-dom";
import getUuidByString from "uuid-by-string";
import { CalendarLink } from "../../interface/Calendar";
import useGet from "../../Hooks/useGet";
import Paragraph from "antd/es/typography/Paragraph";
import "../../index.css";
import { RcFile, UploadProps } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import Loader from "../Loader";
import { userRoles } from "../../enums/Roles";
import { BASE_URL } from "../../constant";
import { Project } from "../../interface/Project";
import { Holiday } from "../../interface/Holiday";
import { colors } from "../../Theme/Theme";

interface Event {
  id: number;
  start: Date;
  end: Date;
  title: string;
  type: string;
  desc: string;
  contact: string;
  appliedOn: string;
  allDay: boolean;
}

const locales = {
  "en-US": require("date-fns"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const items: MenuProps["items"] = [
  {
    label: "All",
    key: "0",
  },

  {
    label: "Work From Home",
    key: "2",
  },

  {
    label: "On Leave",
    key: "4",
  },
];

export const Leaves = () => {
  const userType = localStorage.getItem("role");
  const token = localStorage.getItem("idToken");

  const { projectHash } = useParams();
  const [showEvents, setShowEvents] = useState<Event[]>([]);
  const [show, setShow] = useState(false);
  const [active, setActive] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [link, setLink] = useState("");
  const [project, setProject] = useState("");
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [uploadYear, setUploadYear] = useState<string>("");

  const [holidaysList, setHolidaysList] = useState<Holiday[]>([]);

  const navigate = useNavigate();

  const { call } = useFetch("POST");
  const { Title, Text } = Typography;

  const getData = (data: CalendarLink) => {
    if (data) {
      setProject(data.project);
    }
  };

  const getEvents = (data: Event[]) => {
    if (data) {
      data.forEach((e) => (e.allDay = true));
      setEvents(data);
    }
    setIsLoading(false);
  };

  const getProjectList = (data: Project[]) => {
    if (data) {
      setProjectList(data);
    }
    setIsLoading(false);
  };

  const getHolidaysListData = (data: Holiday[]) => {
    if (data) {
      setHolidaysList(data);
    }
  };

  const getProjectsData = useGet(`/calendar/${projectHash}`, getData);

  const url = projectHash
    ? `/calendar/leaves?projectHash=${projectHash}`
    : `/calendar/leaves?projectName=${
        project === "" ? "all-projects" : project
      }`;

  const getCalendar = useGet(url, getEvents);

  const getProjects = useGet("/project/all", getProjectList);

  const getHolidays = useGet("/holidays/all", getHolidaysListData);

  useEffect(() => {
    setIsLoading(true);

    getHolidays();
    getProjects();
  }, [getHolidays, getProjects]);

  useEffect(() => {
    setIsLoading(true);

    if (projectHash) {
      getProjectsData();
    }
    getCalendar();
  }, [getCalendar, getProjectsData, project, projectHash]);

  const projectItems: MenuProps["items"] = [
    { label: "All Projects", key: 0 },
    ...projectList.map((p, i) => {
      return {
        label: p.name,
        key: i + 1,
      };
    }),
  ];

  const ColoredDateCellWrapper = ({ children, value }: any) => {
    const valueDate = moment(value);
    let isToday = false;
    let isWeekend = false;
    let isHoliday = false;

    const holiday = holidaysList?.find(
      (h) => h.holidayDate === valueDate.format("yyyy-MM-DD")
    );

    if (
      valueDate.date() === moment().date() &&
      valueDate.month() === moment().month() &&
      valueDate.year() === moment().year()
    ) {
      isToday = true;
    }

    if (valueDate.weekday() === 6 || valueDate.weekday() === 0) {
      isWeekend = true;
    }

    if (holiday) {
      isHoliday = true;
    }

    const child = isHoliday && (
      <p
        style={{
          fontSize: "0.7rem",
          margin: "0.4rem",
        }}
      >
        {holiday?.description}
      </p>
    );

    return React.cloneElement(
      children,
      {
        style: {
          ...children.style,
          fontWeight: 600,
          backgroundColor:
            (isToday && "#FBFECE ") ||
            (isWeekend && "#DAEAF1") ||
            (isHoliday && "#ECD8FE") ||
            colors.background,
        },
      },
      child
    );
  };

  const handleMore = (events: Event[]) => {
    setShow(true);
    setShowEvents(events.filter((e) => e.type !== "Comp - Off"));
  };

  const handleSelectEvent = (event: Event) => {
    const selected: Event[] = [];
    selected.push(event);
    setShowEvents(selected);
    setShow(true);
  };

  const onClose = () => {
    setShow(false);
  };

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "0") {
      setActive("");
    } else if (key === "2") {
      setActive("Work From Home");
    } else if (key === "4") {
      setActive("On Leave");
    }
  };

  const projectClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "0") {
      setProject("All Projects");
    } else {
      setProject(projectList[Number(key) - 1].name);
    }
  };

  const handleCancel = () => {
    setIsModal(false);
    setProject("all-projects");
    setLink("");
  };

  const handleUploadCancel = () => {
    setIsUploadModal(false);
  };

  const handleSelect = () => {
    setIsLoading(true);
    const hash = getUuidByString(project);

    const postRequest = {
      hash,
      project,
    };

    call(
      "/calendar/share-link",
      postRequest,
      (data: CalendarLink) => {
        setLink(`${window.location.host}/leaves-calendar/${data.hash}`);
        setIsLoading(false);
      },
      (error) => {
        notification.warning({
          message: "An Error Occurred",
        });
      }
    );
  };

  const handleOk = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("leavesFile", file as RcFile);
    });
    formData.append("year", uploadYear);
    fetch(`${BASE_URL}/calendar/leaves`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.status === 400) {
          throw new Error("Bad Request");
        }
        return res.json();
      })
      .then((data) => {
        if (data) {
          setIsLoading(false);
          setFileList([]);
          getCalendar();
          message.success("upload successfully.");
          setIsUploadModal(false);
        } else {
          message.error("upload failed.");
        }
      })
      .catch(() => {
        message.error("Please select year");
      });
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (file.name.endsWith(".xls")) {
        setFileList([...fileList, file]);
      } else {
        notification.error({ message: "Wrong File type." });
      }
      return false;
    },
    fileList,
    onDrop(e) {},
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setUploadYear(dateString);
  };

  return (
    <div
      style={{
        height: projectHash ? "97vh" : "90vh",
        padding: projectHash ? "1.5rem" : "1rem",
        overflowY: "auto",
      }}
    >
      {isLoading ? (
        <Loader loading={isLoading} message="Fetching Leaves Data" />
      ) : (
        <>
          {projectHash && (
            <Title level={2}>
              {project === "All Projects" ? "Studio SleevesUp" : project}{" "}
            </Title>
          )}
          <div style={leaveHeader}>
            <Dropdown.Button
              menu={{ items, onClick }}
              placement="bottomLeft"
              trigger={["click"]}
              icon={<DownOutlined />}
              style={{ width: "50%" }}
            >
              <Space>
                {active.length > 0 ? `${active}` : "Select Leave type"}
              </Space>
            </Dropdown.Button>
            {!projectHash && (
              <Dropdown.Button
                menu={{ items: projectItems, onClick: projectClick }}
                placement="bottomLeft"
                trigger={["click"]}
                icon={<DownOutlined />}
                style={{ justifyContent: "end", marginRight: "1rem" }}
              >
                <Space>
                  {project.length > 0 ? `${project}` : "Select Project"}
                </Space>
              </Dropdown.Button>
            )}
            {(userType === userRoles.Admin || userType === userRoles.HR) && (
              <Button type="primary" onClick={() => navigate("/leaves/report")}>
                Get Report
              </Button>
            )}
          </div>
          <div style={legendList}>
            <div style={legend}>
              <div style={{ ...colorBox, backgroundColor: "#FBFECE" }}></div>
              <p style={legendText}>Today</p>
            </div>
            <div style={legend}>
              <div style={{ ...colorBox, backgroundColor: "#ECD8FE" }}></div>
              <p style={legendText}>Holiday</p>
            </div>
            <div style={legend}>
              <div style={{ ...colorBox, backgroundColor: "#A7CBFA" }}></div>
              <p style={legendText}>Work From Home</p>
            </div>
            <div style={legend}>
              <div style={{ ...colorBox, backgroundColor: "#FAB0A7" }}></div>
              <p style={legendText}>On Leave</p>
            </div>
            <div style={legend}>
              <div style={{ ...colorBox, backgroundColor: "#A7FACF" }}></div>
              <p style={legendText}>Comp - Off</p>
            </div>
          </div>
          <Calendar
            events={
              active === ""
                ? events.filter((e) => e.type !== "Work From Home")
                : events.filter((e) => {
                    return active === "On Leave"
                      ? e.type === "Loss Of Pay" || e.type === "Annual Leave"
                      : e.type === active;
                  })
            }
            views={["month", "week"]}
            localizer={localizer}
            defaultDate={new Date()}
            style={{
              height: projectHash ? "700px" : "600px",
              marginTop: "1rem",
            }}
            components={{
              dateCellWrapper: ColoredDateCellWrapper,
            }}
            startAccessor={(event) => {
              return new Date(event.start);
            }}
            endAccessor={(event) => {
              return new Date(event.end);
            }}
            onShowMore={handleMore}
            onSelectEvent={handleSelectEvent}
            eventPropGetter={(event) => {
              let backgroundColor = "";
              switch (event.type) {
                case "Work From Home":
                  backgroundColor = "#A7CBFA";
                  break;
                case "Annual Leave":
                case "Loss Of Pay":
                  backgroundColor = "#FAB0A7";
                  break;
                case "Comp - Off":
                  backgroundColor = "#A7FACF";
                  break;
              }
              return {
                style: {
                  backgroundColor,
                  borderRadius: 4,
                  margin: "2px 0",
                  color: "black",
                  fontSize: "1rem",
                },
              };
            }}
          />
          <Drawer
            title="People on Leave"
            placement="right"
            onClose={onClose}
            open={show}
          >
            <List
              dataSource={showEvents}
              renderItem={(item, idx) => (
                <List.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <List.Item.Meta
                      title={`${item.id} - ${item.title}`}
                      description={item.desc}
                    />
                    <Text>{`Applied On - ${item.appliedOn}`}</Text>
                    <Text>{item.contact && `Contact - ${item.contact}`}</Text>
                    <Text>{item.type}</Text>
                  </div>
                </List.Item>
              )}
            />
          </Drawer>
          {!projectHash && (
            <FloatButton
              type="primary"
              tooltip="Get sharable link"
              icon={<ShareAltOutlined />}
              onClick={() => setIsModal(true)}
            />
          )}
          {!projectHash && userType === userRoles.Admin && (
            <FloatButton
              type="primary"
              tooltip="Upload Leaves File"
              icon={<FileAddOutlined />}
              style={{ right: 94 }}
              onClick={() => setIsUploadModal(true)}
            />
          )}
          <Modal
            style={{ top: "50px" }}
            title="Share Calendar"
            open={isModal}
            onCancel={handleCancel}
            footer={null}
          >
            <Title level={3}>Choose Project</Title>

            <Select
              showSearch
              value={project}
              placeholder="Select a Project"
              className="search-user"
              optionFilterProp="children"
              onChange={(data) => setProject(data)}
              filterOption={(input, option) =>
                (option?.value ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.value ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.value ?? "").toLowerCase())
              }
              options={[
                { value: "All Projects" },
                ...projectList.map((p) => {
                  return {
                    value: p.name,
                  };
                }),
              ]}
              style={{ width: "60%" }}
            />
            <Button
              type="primary"
              onClick={handleSelect}
              style={{ marginLeft: "2rem" }}
              disabled={project ? false : true}
            >
              Get Link
            </Button>
            {link && (
              <>
                <Title level={4} style={{ marginTop: "1rem" }}>
                  Your Sharable Link
                </Title>
                <Paragraph ellipsis copyable underline>
                  {link}
                </Paragraph>
              </>
            )}
          </Modal>
          <Modal
            style={{ top: "50px" }}
            title="Leaves File"
            open={isUploadModal}
            onCancel={handleUploadCancel}
            footer={
              <Button
                key="submit"
                type="primary"
                onClick={handleOk}
                disabled={fileList.length === 0}
              >
                Save
              </Button>
            }
          >
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click to select the file to be uploaded
              </p>
            </Dragger>
            <Title level={4} style={{ marginTop: "1rem" }}>
              Choose Year of Leaves Uploaded
            </Title>
            <DatePicker onChange={onChange} picker="year" />
          </Modal>
        </>
      )}
    </div>
  );
};
