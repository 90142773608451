import React, { useState } from "react";
import { Dropdown, Modal, Checkbox, Button, MenuProps, Space } from "antd";
import { checkBox, checkBoxItems, skillList } from "../../Styles/FilterStyle";
import { UserDetails } from "../../interface/User";

interface FilterButtonProps {
  userList: UserDetails[];
  onFilteredUsersSkillsChange: (filteredUsersSkills: any[]) => void;
  onFilteredDesignationsChange: (filteredDesignations: any[]) => void;
  onFilteredProjectsChange: (filteredProjects: any[]) => void;
  onFilteredBillabilityChange: (filteredSlabs: any[]) => void;
  selectedSkills: string[];
  selectedDesignations: string[];
  selectedProjects: string[];
  selectedSlabs: string[];
  setSelectedSkills: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedDesignations: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedProjects: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedSlabs: React.Dispatch<React.SetStateAction<string[]>>;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  userList,
  onFilteredUsersSkillsChange,
  onFilteredDesignationsChange,
  onFilteredProjectsChange,
  onFilteredBillabilityChange,
  selectedSkills,
  selectedDesignations,
  selectedProjects,
  selectedSlabs,
  setSelectedDesignations,
  setSelectedSkills,
  setSelectedProjects,
  setSelectedSlabs,
}) => {
  const [skillModalVisible, setSkillModalVisible] = useState(false);
  const [designationModalVisible, setDesignationModalVisible] = useState(false);
  const [projectModalVisible, setProjectModalVisible] = useState(false);
  const [billabilityModalVisible, setBillabilityModalVisible] = useState(false);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "skills") {
      setSkillModalVisible(true);
    } else if (key === "designation") {
      setDesignationModalVisible(true);
    } else if (key === "projects") {
      setProjectModalVisible(true);
    } else if (key === "billability") {
      setBillabilityModalVisible(true);
    }
  };

  const handleSkillCheckboxChange = (checkedValues: any) => {
    setSelectedSkills(checkedValues);
  };

  const handleDesignationCheckboxChange = (checkedValues: any) => {
    setSelectedDesignations(checkedValues);
  };
  const handleProjectCheckboxChange = (checkedValues: any) => {
    setSelectedProjects(checkedValues);
  };
  const handleSlabCheckboxChange = (checkedValues: any) => {
    setSelectedSlabs(checkedValues);
  };

  const handleSkillApply = () => {
    let filteredData = [];
    if (selectedSkills.length === 0) {
      filteredData = userList.map((userSkill) => userSkill.email);
    } else {
      filteredData = userList
        .filter((userSkill) =>
          userSkill.skills?.some((skill) =>
            selectedSkills.includes(skill.skill.name)
          )
        )
        .map((userSkill) => userSkill.email);
    }
    setSkillModalVisible(false);
    onFilteredUsersSkillsChange(filteredData);
    setSelectedDesignations([]);
    setSelectedProjects([]);
    setSelectedSlabs([]);
  };

  const handleDesignationApply = () => {
    let filteredData = [];
    if (selectedDesignations.length === 0) {
      filteredData = userList.map((userDesignation) => userDesignation.email);
    } else {
      filteredData = userList
        .filter((userDesignation) => {
          const designation = userDesignation.designation?.designation;
          return designation && selectedDesignations.includes(designation);
        })
        .map((userDesignation) => userDesignation.email);
    }
    setDesignationModalVisible(false);
    onFilteredDesignationsChange(filteredData);
    setSelectedSkills([]);
    setSelectedProjects([]);
    setSelectedSlabs([]);
  };

  const handleProjectApply = () => {
    let filteredData = [];
    if (selectedProjects.length === 0) {
      filteredData = userList.map((userProject) => userProject.email);
    } else {
      filteredData = userList
        .filter((userProject) =>
          userProject.projects?.some((project) =>
            selectedProjects.includes(project.projectName)
          )
        )
        .map((userProject) => userProject.email);
    }
    setProjectModalVisible(false);
    onFilteredProjectsChange(filteredData);
    setSelectedDesignations([]);
    setSelectedSkills([]);
    setSelectedSlabs([]);
  };

  const handleBillabilityApply = () => {
    let filteredData = [];
    if (selectedSlabs.length === 0) {
      filteredData = userList.map((userProject) => userProject.email);
    } else {
      filteredData = userList
        .filter((userProject) => {
          const hasNonBillable = selectedSlabs.includes("Non Billable");
          const hasNullProjects =
            userProject.projects === null || userProject.projects.length === 0;
          const hasMatchingProjects = userProject.projects?.some((project) =>
            selectedSlabs.includes(project.billingStatus)
          );
          return hasNonBillable
            ? hasNullProjects || hasMatchingProjects
            : hasMatchingProjects;
        })
        .map((userProject) => userProject.email);
    }
    setBillabilityModalVisible(false);
    onFilteredBillabilityChange(filteredData);
    setSelectedDesignations([]);
    setSelectedSkills([]);
    setSelectedProjects([]);
  };

  const closeSkillModal = () => {
    setSkillModalVisible(false);
  };

  const closeDesignationModal = () => {
    setDesignationModalVisible(false);
    setSelectedSkills([]);
  };

  const closeProjectModal = () => {
    setProjectModalVisible(false);
    setSelectedSkills([]);
    setSelectedDesignations([]);
  };

  const closeBillabilityModal = () => {
    setBillabilityModalVisible(false);
    setSelectedSkills([]);
    setSelectedDesignations([]);
    setSelectedProjects([]);
  };

  const items: MenuProps["items"] = [
    {
      key: "skills",
      label: "Skills",
    },
    {
      key: "designation",
      label: "Designations",
    },
    {
      key: "projects",
      label: "Projects",
    },
    {
      key: "billability",
      label: "Billing",
    },
  ];

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Dropdown.Button menu={{ items, onClick }} trigger={["click"]}>
        <Space>Filter By:</Space>
      </Dropdown.Button>

      <Modal
        title="Skills"
        open={skillModalVisible}
        onCancel={closeSkillModal}
        footer={[
          <Button key="cancel" onClick={closeSkillModal}>
            Cancel
          </Button>,
          <Button key="apply" type="primary" onClick={handleSkillApply}>
            Apply
          </Button>,
        ]}
      >
        <Checkbox.Group
          style={checkBox}
          onChange={handleSkillCheckboxChange}
          value={selectedSkills}
        >
          {Array.from(
            new Set(
              userList.flatMap(
                (user) => user.skills?.map((skill) => skill.skill.name) || []
              )
            )
          ).map((skillName: string, index: number) => (
            <div style={skillList} key={index}>
              <Checkbox value={skillName}>{skillName}</Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      </Modal>

      <Modal
        title="Designations"
        open={designationModalVisible}
        onCancel={closeDesignationModal}
        footer={[
          <Button key="cancel" onClick={closeDesignationModal}>
            Cancel
          </Button>,
          <Button key="apply" type="primary" onClick={handleDesignationApply}>
            Apply
          </Button>,
        ]}
      >
        <Checkbox.Group
          style={checkBox}
          onChange={handleDesignationCheckboxChange}
          value={selectedDesignations}
        >
          {Array.from(
            new Set(
              userList.map(
                (userDesignation) =>
                  userDesignation.designation?.designation || ""
              )
            )
          ).map((designation: string, index: number) => (
            <div style={skillList} key={index}>
              <Checkbox value={designation}>{designation}</Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      </Modal>
      <Modal
        title="Projects"
        open={projectModalVisible}
        onCancel={closeProjectModal}
        footer={[
          <Button key="cancel" onClick={closeProjectModal}>
            Cancel
          </Button>,
          <Button key="apply" type="primary" onClick={handleProjectApply}>
            Apply
          </Button>,
        ]}
      >
        <Checkbox.Group
          style={checkBox}
          onChange={handleProjectCheckboxChange}
          value={selectedProjects}
        >
          {Array.from(
            new Set(
              userList.flatMap(
                (user) =>
                  user.projects?.map((project) => project.projectName) || []
              )
            )
          ).map((projectName: string, index: number) => (
            <div style={skillList} key={index}>
              <Checkbox value={projectName}>{projectName}</Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      </Modal>
      <Modal
        title="Billing"
        open={billabilityModalVisible}
        onCancel={closeBillabilityModal}
        footer={[
          <Button key="cancel" onClick={closeBillabilityModal}>
            Cancel
          </Button>,
          <Button key="apply" type="primary" onClick={handleBillabilityApply}>
            Apply
          </Button>,
        ]}
      >
        <Checkbox.Group
          style={checkBox}
          onChange={handleSlabCheckboxChange}
          value={selectedSlabs}
        >
          <Checkbox style={checkBoxItems} value="Fully Billable">
            Fully Billable
          </Checkbox>
          <Checkbox style={checkBoxItems} value="Partially Billable">
            Partially Billable
          </Checkbox>
          <Checkbox style={checkBoxItems} value="Non Billable">
            Non Billable
          </Checkbox>
        </Checkbox.Group>
      </Modal>
    </div>
  );
};

export default FilterButton;
