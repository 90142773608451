import { useEffect, useState } from "react";
import Landing from "../Components/HomeComponents/Landing";
import useGet from "../Hooks/useGet";
import User from "../interface/User";
import { homePageMain } from "../Styles/ProfilePage/Landing";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";

export const HomePage = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const url = `/user/${userId}`;
  const getUserDetails = useGet(url, getData);

  function getData(data: User) {
    if (data != null) {
      setIsLoading(false);
      setUser(data);
    } else {
      navigate("login");
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getUserDetails();
  }, [getUserDetails]);

  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} message="Loading User" />
      ) : (
        <div style={homePageMain}>
          <Landing user={user} />
        </div>
      )}
    </>
  );
};
