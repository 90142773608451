import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../constant";
import {
  levelMap,
  skillTable,
  tableHeading1,
  tableHeading2,
  tableHeading3,
  tableHeading4,
} from "../../Styles/SkillTableStyle";
import { Tooltip } from "antd";
import { Skill } from "../../interface/Skill";

interface UserSkill {
  skillId: number;
  level: string;
  count: number;
  userList: string[];
}

const SkillTable: React.FC = () => {
  const [userSkills, setUserSkills] = useState<UserSkill[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("idToken");
      try {
        const userSkillsResponse = await fetch(
          `${BASE_URL}/user/skill/level-count`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const userSkillsData = await userSkillsResponse.json();
        setUserSkills(userSkillsData.data);

        const skillsResponse = await fetch(`${BASE_URL}/skill/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const skillsData = await skillsResponse.json();
        setSkills(skillsData.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const uniqueSkills = Array.from(
    new Set(userSkills.map((skill) => skill.skillId))
  );

  const getSkillName = (skillId: number) => {
    const skill = skills.find((skill) => skill.id === skillId);
    return skill ? skill.name : "";
  };

  const getSkillLevelCount = (skillId: number, level: string) => {
    const matchingSkill = userSkills.find(
      (skill) => skill.skillId === skillId && skill.level === level
    );
    return matchingSkill ? matchingSkill.count : 0;
  };

  const getUserNames = (skillId: number, level: string) => {
    const matchingSkill = userSkills.find(
      (skill) => skill.skillId === skillId && skill.level === level
    );
    return matchingSkill ? matchingSkill.userList : "";
  };

  const hoverableCell = (skillId: number, level: string) => {
    const count = getSkillLevelCount(skillId, level);
    const userName = getUserNames(skillId, level);
    return (
      <Tooltip placement="top" title={userName}>
        <span style={{ cursor: "pointer" }}>{count}</span>
      </Tooltip>
    );
  };

  return (
    <div style={skillTable}>
      <table style={levelMap}>
        <tbody>
          <tr>
            <th style={tableHeading1}>Skill Name</th>
            {uniqueSkills.map((skillId: number) => (
              <td key={skillId} style={tableHeading1}>
                {getSkillName(skillId)}
              </td>
            ))}
          </tr>

          <tr>
            <th style={tableHeading2}>Beginner</th>
            {uniqueSkills.map((skillId: number) => (
              <td key={skillId} style={tableHeading2}>
                {hoverableCell(skillId, "BEGINNER")}
              </td>
            ))}
          </tr>
          <tr>
            <th style={tableHeading3}>Intermediate</th>
            {uniqueSkills.map((skillId: number) => (
              <td key={skillId} style={tableHeading3}>
                {hoverableCell(skillId, "INTERMEDIATE")}
              </td>
            ))}
          </tr>
          <tr>
            <th style={tableHeading4}>Expert</th>
            {uniqueSkills.map((skillId: number) => (
              <td key={skillId} style={tableHeading4}>
                {hoverableCell(skillId, "EXPERT")}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SkillTable;
