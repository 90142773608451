import React from "react";
import Project from "../Components/Project/Project";
import { Typography } from "antd";

const { Title } = Typography;

export default function ProjectPage() {
  return (
    <div style={{ padding: "1rem" }}>
      <Title level={3}>Projects</Title>
      <Project />
    </div>
  );
}
