import React, { useEffect, useState } from "react";
import { InputBox } from "./InputBox";
import { Button, Typography, notification } from "antd";
import {
  floatButtonStyle,
  profileSections,
} from "../../Styles/ProfilePage/Profile";
import { ProfileHeader } from "./ProfileHeader";
import useGet from "../../Hooks/useGet";
import useFetch from "../../Hooks/useFetch";

import { NumberBox } from "./NumberBox";
import Loader from "../Loader";
import { AccountData } from "../../interface/UserAccount";

const AccountDetails = () => {
  const [bankName, setBankName] = useState<string>();
  const [branchName, setBranchName] = useState<string>();
  const [accountNumber, setAccountNumber] = useState<string>();
  const [ifscCode, setIfscCode] = useState<string>();
  const [panCardNumber, setPanCardNumber] = useState<string>();

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [accountData, setAccountData] = useState<AccountData>();

  const userId = localStorage.getItem("userId");

  const { call } = useFetch("PUT");

  const getAccountData = (data: AccountData) => {
    setAccountData(data);
    setBankName(data.bankName);
    setBranchName(data.branchName);
    setAccountNumber(data.accountNumber);
    setIfscCode(data.ifscCode);
    setPanCardNumber(data.panCardNumber);
    setIsLoading(false);
  };

  const getAccountDetails = useGet(`/user/${userId}/account`, getAccountData);

  useEffect(() => {
    getAccountDetails();
  }, []);

  const setCancelHandler = () => {
    setBankName(accountData?.bankName);
    setBranchName(accountData?.branchName);
    setAccountNumber(accountData?.accountNumber);
    setIfscCode(accountData?.ifscCode);
    setPanCardNumber(accountData?.panCardNumber);
  };

  const accountDataSubmitHandler = () => {
    setIsLoading(true);
    const newAccountData = {
      userId: userId,
      bankName: bankName ? bankName : accountData?.bankName,
      branchName: branchName ? branchName : accountData?.branchName,
      accountNumber: accountNumber ? accountNumber : accountData?.accountNumber,
      ifscCode: ifscCode ? ifscCode : accountData?.ifscCode,
      panCardNumber: panCardNumber ? panCardNumber : accountData?.panCardNumber,
    };

    call(
      `/user/${userId}/account`,
      newAccountData,
      () => {
        notification.success({
          message: "Account Details Updated",
        });
        getAccountDetails();
      },
      () => {
        setIsLoading(false);
        notification.warning({
          message: "Something went wrong",
        });
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} message="Loading Account Details.." />
      ) : (
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <ProfileHeader
              isEditable={isEditable}
              setIsEditable={setIsEditable}
            />
          </div>
          <div
            style={{
              ...profileSections,
              height: "75vh",
              overflowY: "auto",
            }}
          >
            <Typography.Title level={2}>Account Details</Typography.Title>
            <Typography.Title level={4}>Bank Name</Typography.Title>
            <InputBox
              data={bankName}
              isEditable={isEditable}
              setState={setBankName}
              placeholder={"Bank Name"}
            />
            <Typography.Title level={4}>Branch Name</Typography.Title>
            <InputBox
              data={branchName}
              isEditable={isEditable}
              setState={setBranchName}
              placeholder={"Branch Name"}
            />
            <Typography.Title level={4}>Account Number</Typography.Title>
            <NumberBox
              data={accountNumber}
              isEditable={isEditable}
              setState={setAccountNumber}
              placeholder={"Account Number"}
            />
            <Typography.Title level={4}>IFSC Code</Typography.Title>
            <InputBox
              data={ifscCode}
              isEditable={isEditable}
              setState={setIfscCode}
              placeholder={"IFSC Code"}
            />
            <Typography.Title level={4}>
              PAN (Permanent Account Number)
            </Typography.Title>
            <InputBox
              data={panCardNumber}
              isEditable={isEditable}
              setState={setPanCardNumber}
              placeholder={"PAN (Permanent Account Number)"}
            />
          </div>
          {isEditable && (
            <>
              <Button
                type="primary"
                style={floatButtonStyle}
                onClick={() => {
                  setIsEditable(false);
                  accountDataSubmitHandler();
                  setIsLoading(true);
                }}
              >
                SAVE
              </Button>

              <Button
                type="primary"
                style={floatButtonStyle}
                onClick={() => {
                  setIsEditable(false);
                  setCancelHandler();
                }}
              >
                CANCEL
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AccountDetails;
