import React from "react";
import Course from "../../interface/Course";
import {
  courseDuration,
  courseNameStyling,
  linkStyle,
} from "../../Styles/BootCampStyle";
import { BsFillEasel2Fill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Tag, Tooltip } from "antd";
import { colors } from "../../Theme/Theme";

export default function CourseList({
  data,
  setSelected,
}: {
  data: Course;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
}) {
  return (
    <div style={linkStyle}>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div style={{ flex: 1 }}>
          <BsFillEasel2Fill
            color={colors.primary}
            size={70}
            style={{ paddingTop: "2rem" }}
          />
        </div>
        <div style={{ flex: 6 }}>
          <Link to={`/resources/${data.id}`}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "1rem",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={courseNameStyling}>{data.name}</div>

              <div style={courseDuration}>
                <Tag
                  color={colors.addTag}
                  style={{ color: colors.background, fontWeight: 600 }}
                >
                  {data.duration}h
                </Tag>
              </div>
            </div>
            <div
              style={{
                color: "#004c4c",
                fontSize: "0.8rem",
                fontWeight: "600",
                marginTop: "0.2rem",
              }}
            >
              by: {data.createdBy}
            </div>

            <Tooltip title={data.description} placement="bottomLeft">
              <Tag
                color="processing"
                style={{
                  fontWeight: 600,
                  marginTop: "0.5rem",
                  width: "auto",
                  wordWrap: "normal",
                }}
              >
                {data.description.length > 25
                  ? data.description.slice(0, 25) + "..."
                  : data.description}
              </Tag>
            </Tooltip>
          </Link>
        </div>
      </div>
    </div>
  );
}
