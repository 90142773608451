import React from "react";
import { Pdf } from "../../interface/Pdf";
import Link from "antd/es/typography/Link";
import { pdfStyling } from "../../Styles/BootCampStyle";
import { BASE_URL, fileType } from "../../constant";
import { colors } from "../../Theme/Theme";
import { BsFiletypePdf } from "react-icons/bs";

export default function ResourceList({
  data,
  setLoading,
}: {
  data: Pdf;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const token = localStorage.getItem("idToken");

  const handleClick = (fileName: String) => {
    setLoading(true);
    fetch(`${BASE_URL}/course/?path=${fileName}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      response.blob().then((blob) => {
        var b = new Blob([blob], { type: fileType });
        var url = window.URL.createObjectURL(b);
        setLoading(false);
        window.open(url);
      });
    });
  };
  return (
    <div style={pdfStyling}>
      <BsFiletypePdf
        style={{
          padding: "0.5rem",
          fontSize: "2.5rem",
          color: colors.primary,
        }}
      />
      <Link
        onClick={() => handleClick(data.file)}
        style={{ marginLeft: "8px", display: "flex", alignItems: "center" }}
      >
        <div style={{ fontSize: "1rem" }}>{data.name}</div>
      </Link>
    </div>
  );
}
