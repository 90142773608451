import React, { useState } from "react";
import { form } from "../../Styles/OnboardingPage/OnboardingStyles";
import { Onboarding } from "../../interface/Onboarding";
import Link from "antd/es/typography/Link";
import {
  Button,
  FloatButton,
  Upload,
  UploadFile,
  UploadProps,
  message,
  notification,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import Loader from "../Loader";
import { BASE_URL, fileSize, fileType } from "../../constant";
import { useParams } from "react-router-dom";

function DocumentList({ data }: { data: Onboarding }) {
  const [sscMarksheet, setSscMarksheet] = useState<UploadFile[]>([]);
  const [hscMarksheet, setHscMarksheet] = useState<UploadFile[]>([]);
  const [collegeMarksheet, setCollegeMarksheet] = useState<UploadFile[]>([]);
  const [voluntaryForm, setVoluntaryForm] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);

  const { userId } = useParams();

  const loggedInid = localStorage.getItem("userId");

  const props: UploadProps = {
    disabled: loggedInid === userId ? false : true,
    onDrop(e) {
      message.error("upload failed.");
    },
  };

  const token = localStorage.getItem("idToken");
  const handleClick = (fileName: String) => {
    setLoading(true);
    fetch(`${BASE_URL}/onboarding?path=${fileName}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      response.blob().then((blob) => {
        var b = new Blob([blob], { type: fileType });
        var url = window.URL.createObjectURL(b);
        setLoading(false);
        window.open(url);
      });
    });
  };

  const handleOk = () => {
    const token = localStorage.getItem("idToken");
    const formData = new FormData();

    if (sscMarksheet.length > 0) {
      sscMarksheet.forEach((file) => {
        formData.append("sscMarksheet", file as RcFile);
      });
    }

    if (hscMarksheet.length > 0) {
      hscMarksheet.forEach((file) => {
        formData.append("hscMarksheet", file as RcFile);
      });
    }

    if (collegeMarksheet.length > 0) {
      collegeMarksheet.forEach((file) => {
        formData.append("collegeMarksheet", file as RcFile);
      });
    }

    if (voluntaryForm.length > 0) {
      voluntaryForm.forEach((file) => {
        formData.append("voluntaryPfForm", file as RcFile);
      });
    }

    const userId = localStorage.getItem("userId");
    fetch(`${BASE_URL}/onboarding/${userId}`, {
      method: "PUT",
      body: formData,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setCollegeMarksheet([]);
          setHscMarksheet([]);
          setSscMarksheet([]);
          setVoluntaryForm([]);
          message.success("upload successfully.");
          window.location.reload();
        } else {
          message.success("User Exists");
        }
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {});
  };

  return (
    <>
      {loading ? (
        <Loader loading={loading} message="Loading PDF" />
      ) : (
        <div style={form}>
          <h3 style={{ paddingTop: "1rem" }}>
            Pan Card -{" "}
            <Link onClick={() => handleClick(data.panCardPath)}>
              {data.panCard}
            </Link>{" "}
          </h3>
          <h3 style={{ paddingTop: "1rem" }}>
            Aadhar Card -{" "}
            <Link onClick={() => handleClick(data.aadharCardPath)}>
              {data.aadharCard}
            </Link>{" "}
          </h3>
          <h3 style={{ paddingTop: "1rem" }}>
            10th Marksheet -{" "}
            {data.sscMarksheetPath ? (
              <Link onClick={() => handleClick(data.sscMarksheetPath)}>
                {data.sscMarksheet}
              </Link>
            ) : (
              <Upload
                {...props}
                onRemove={(file) => {
                  const index = sscMarksheet.indexOf(file);
                  const newFileList = sscMarksheet.slice();
                  newFileList.splice(index, 1);
                  setSscMarksheet(newFileList);
                }}
                beforeUpload={(file) => {
                  if (file.type === fileType) {
                    if (file.size < fileSize) {
                      setSscMarksheet([...voluntaryForm, file]);
                    } else {
                      notification.error({
                        message: "File size limit exceeded.",
                      });
                    }
                  } else {
                    notification.error({ message: "Wrong File type" });
                  }
                  return false;
                }}
                fileList={sscMarksheet}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            )}
          </h3>
          <h3 style={{ paddingTop: "1rem" }}>
            12th Marksheet -{" "}
            {data.hscMarksheetPath ? (
              <Link onClick={() => handleClick(data.hscMarksheetPath)}>
                {data.hscMarksheet}
              </Link>
            ) : (
              <Upload
                {...props}
                onRemove={(file) => {
                  const index = hscMarksheet.indexOf(file);
                  const newFileList = hscMarksheet.slice();
                  newFileList.splice(index, 1);
                  setHscMarksheet(newFileList);
                }}
                beforeUpload={(file) => {
                  if (file.type === fileType) {
                    if (file.size < fileSize) {
                      setHscMarksheet([...voluntaryForm, file]);
                    } else {
                      notification.error({
                        message: "File size limit exceeded.",
                      });
                    }
                  } else {
                    notification.error({ message: "Wrong File type" });
                  }
                  return false;
                }}
                fileList={hscMarksheet}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            )}
          </h3>
          <h3 style={{ paddingTop: "1rem" }}>
            College Marksheet -{" "}
            {data.collegeMarksheetPath ? (
              <Link onClick={() => handleClick(data.collegeMarksheetPath)}>
                {data.collegeMarksheet}
              </Link>
            ) : (
              <Upload
                {...props}
                onRemove={(file) => {
                  const index = collegeMarksheet.indexOf(file);
                  const newFileList = collegeMarksheet.slice();
                  newFileList.splice(index, 1);
                  setCollegeMarksheet(newFileList);
                }}
                beforeUpload={(file) => {
                  if (file.type === fileType) {
                    if (file.size < fileSize) {
                      setCollegeMarksheet([...voluntaryForm, file]);
                    } else {
                      notification.error({
                        message: "File size limit exceeded.",
                      });
                    }
                  } else {
                    notification.error({ message: "Wrong File type" });
                  }
                  return false;
                }}
                fileList={collegeMarksheet}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            )}
          </h3>
          <h3 style={{ paddingTop: "1rem" }}>
            PF Form -{" "}
            <Link onClick={() => handleClick(data.pfFormPath)}>
              {data.pfForm}
            </Link>{" "}
          </h3>
          <h3 style={{ paddingTop: "1rem" }}>
            Voluntary PF form -{" "}
            {data.voluntaryPfFormPath ? (
              <Link onClick={() => handleClick(data.voluntaryPfFormPath)}>
                {data.voluntaryPfForm}{" "}
              </Link>
            ) : (
              <Upload
                {...props}
                onRemove={(file) => {
                  const index = voluntaryForm.indexOf(file);
                  const newFileList = voluntaryForm.slice();
                  newFileList.splice(index, 1);
                  setVoluntaryForm(newFileList);
                }}
                beforeUpload={(file) => {
                  if (file.type === fileType) {
                    if (file.size < fileSize) {
                      setVoluntaryForm([...voluntaryForm, file]);
                    } else {
                      notification.error({
                        message: "File size limit exceeded.",
                      });
                    }
                  } else {
                    notification.error({ message: "Wrong File type" });
                  }
                  return false;
                }}
                fileList={voluntaryForm}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            )}
          </h3>
          {(sscMarksheet.length > 0 ||
            hscMarksheet.length > 0 ||
            collegeMarksheet.length > 0 ||
            voluntaryForm.length > 0) && (
            <FloatButton
              type="primary"
              description="Save"
              shape="square"
              style={{ right: 94, width: "10vw" }}
              onClick={handleOk}
            />
          )}
        </div>
      )}
    </>
  );
}

export default DocumentList;
