import React, { useState } from "react";
import {
  Space,
  Card,
  Image,
  Tooltip,
  Modal,
  Select,
  Typography,
  Button,
  AutoComplete,
  notification,
} from "antd";
import "../../Styles/Organization/ModalView.css";
import {
  cardDetailingStyle,
  cardUserNameStyle,
  contentInsideStyling,
  contentStyling,
  tableViewCardContentStyle,
  tableViewCardStyle,
} from "../../Styles/Organization/Organization";
import { UserDetails } from "../../interface/User";
import { GoMail } from "react-icons/go";
import {
  EditOutlined,
  IdcardOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { userRoles } from "../../enums/Roles";
import { useNavigate } from "react-router-dom";
import useFetch from "../../Hooks/useFetch";
import Sidebar from "./Sidebar";

export default function TableViewCard({
  data,
  userList,
  isActive,
  onCardClick,
  onClose,
}: {
  data: UserDetails;
  userList: UserDetails[];
  isActive: boolean;
  onCardClick: (cardId: string) => void;
  onClose: (cardId: string) => void;
}) {
  const userRole = localStorage.getItem("role");
  const navigate = useNavigate();

  const [isModal, setIsModal] = useState(false);
  const [role, setRole] = useState<String>();

  const [isChangeModal, setIsChangeModal] = useState(false);
  const [manager, setManager] = useState<String>();

  const { Title } = Typography;

  const { call: update } = useFetch("PUT");

  const roleList = [userRoles.Allocation, userRoles.Employee, userRoles.HR];

  const roles: { value: string }[] = roleList.map((role) => {
    return {
      value: role,
    };
  });

  let actions: React.ReactNode[] = [];

  switch (userRole) {
    case userRoles.Admin:
      actions = [
        <Tooltip title="Assign Role" placement="bottom">
          <UserSwitchOutlined key="update" onClick={() => setIsModal(true)} />
        </Tooltip>,
        <Tooltip title="Change Manager" placement="bottom">
          <EditOutlined
            key="edit"
            onClick={() => {
              setIsChangeModal(true);
            }}
          />
        </Tooltip>,
        <Tooltip title="Show Profile" placement="bottom">
          <IdcardOutlined
            key="ellipsis"
            onClick={() => navigate(`/profile/${data.id}`)}
          />
        </Tooltip>,
      ];
      break;
    case userRoles.Allocation:
      actions = [
        <Tooltip title="Change Manager" placement="bottom">
          <EditOutlined key="edit" onClick={() => setIsChangeModal(true)} />
        </Tooltip>,
      ];
      break;
    case userRoles.HR:
      actions = [
        <Tooltip title="Show Profile" placement="bottom">
          <IdcardOutlined
            key="ellipsis"
            onClick={() => navigate(`/profile/${data.id}`)}
          />
        </Tooltip>,
      ];
      break;
    default:
      actions = [];
  }

  const handleCancel = () => {
    setIsModal(false);
    setRole("");
  };

  const handleManagerCancel = () => {
    setIsChangeModal(false);
    setManager("");
  };

  const handleManagerChange = () => {
    const changeRequest = {
      userId: data.id,
      managerName: manager,
    };

    update(
      "/user/manager",
      changeRequest,
      () => {
        notification.success({
          message: "Manager Updated",
        });
        setIsChangeModal(false);
        setManager("");
      },
      (error) => {
        notification.warning({
          message: "An Error Occurred",
        });
      }
    );
  };

  const handleRoleChange = () => {
    const changeRequest = {
      userId: data.id,
      userType: role,
    };

    update(
      "/user/userType",
      changeRequest,
      () => {
        notification.success({
          message: "Role Assigned",
        });
        setIsModal(false);
      },
      (error) => {
        notification.warning({
          message: "An Error Occurred",
        });
      }
    );
  };

  const handleCardClick = () => {
    onCardClick(data.email);
  };

  return (
    <div>
      <Space direction="horizontal" style={{ margin: "0", padding: "0" }}>
        <Card
          size="small"
          hoverable
          style={{
            ...tableViewCardStyle,
            border: isActive ? "2px solid #6DADA4" : "none",
          }}
          actions={actions}
          onClick={handleCardClick}
        >
          <div style={tableViewCardContentStyle}>
            <div>
              <Image
                width={50}
                preview={false}
                style={{ marginLeft: "4rem" }}
                src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
              />
              <div style={cardUserNameStyle}>{data.name}</div>
            </div>
            <div style={cardDetailingStyle}>
              <div style={contentStyling}>
                <GoMail />
                <small style={contentInsideStyling}>{data.email}</small>
              </div>

              {data?.designation && <div>{data?.designation?.designation}</div>}
              <Modal
                style={{ top: "50px" }}
                title="Assign Role"
                open={isModal}
                onCancel={handleCancel}
                footer={
                  <Button
                    type="primary"
                    disabled={role === "" || role === undefined}
                    onClick={handleRoleChange}
                  >
                    Save
                  </Button>
                }
              >
                <Title level={3}>Choose Role</Title>
                <Select
                  showSearch
                  placeholder="Select Role"
                  className="search-user"
                  optionFilterProp="children"
                  onChange={(data) => setRole(data)}
                  filterOption={(input, option) =>
                    (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.value ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.value ?? "").toLowerCase())
                  }
                  options={roles}
                  style={{ width: "60%" }}
                />
              </Modal>
              <Modal
                style={{ top: "50px" }}
                title="Change Manager"
                open={isChangeModal}
                onCancel={handleManagerCancel}
                footer={
                  <Button
                    type="primary"
                    disabled={manager === "" || manager === undefined}
                    onClick={handleManagerChange}
                  >
                    Save
                  </Button>
                }
              >
                <AutoComplete
                  style={{ width: 200 }}
                  options={userList
                    ?.filter((e) => e.id !== data.id)
                    .map((e) => {
                      return {
                        value: e.name,
                      };
                    })}
                  placeholder="Choose a Manager"
                  className="search-user"
                  filterOption={(inputValue, option) =>
                    option!.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onChange={(data) => setManager(data)}
                />
              </Modal>
            </div>
          </div>
        </Card>
        {isActive && (
          <Sidebar data={data} onClose={() => onClose(data.email)} />
        )}
      </Space>
    </div>
  );
}
