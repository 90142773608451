export const checkBox: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  maxHeight: "60vh",
  overflowY: "auto",
};
export const skillList: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginLeft: 0,
  marginBottom: 8,
};
export const checkBoxItems: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginLeft: 0,
  marginBottom: 8,
};
