import React, { useState } from "react";
import { Button, Card, Radio, RadioChangeEvent, Tooltip } from "antd";
import {
  buttonStyle,
  cardDiv,
  radioDivStyle,
  radioGroupStyle,
  skillCardDiv,
  skillNameStyle,
  updateButton,
} from "../../Styles/ProfilePage/SkillDetailsStyle";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { UserSkill } from "../../interface/Skill";
import { colors } from "../../Theme/Theme";

interface SkillCardProps {
  skill: UserSkill;
  handleSave: (userId: number, skillId: number, level: string) => void;
  handleDeleteSkill: (userId: number, skillName: string) => void;
}

const SkillCard: React.FC<SkillCardProps> = ({
  skill,
  handleSave,
  handleDeleteSkill,
}) => {
  const [selectedLevel, setSelectedLevel] = useState(skill.level);

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedLevel(e.target.value);
  };

  const handleSaveClick = () => {
    handleSave(skill.userId, skill.skillId, selectedLevel);
  };

  return (
    <div key={skill.skillId} style={cardDiv}>
      <Card>
        <div style={skillCardDiv}>
          <div style={skillNameStyle}>{skill.skillName}</div>
          <div style={radioDivStyle}>
            <Radio.Group
              style={radioGroupStyle}
              value={selectedLevel}
              onChange={handleRadioChange}
            >
              <Radio value={"BEGINNER"}>BEGINNER</Radio>
              <Radio value={"INTERMEDIATE"}>INTERMEDIATE</Radio>
              <Radio value={"EXPERT"}>EXPERT</Radio>
            </Radio.Group>
          </div>
          <div style={buttonStyle}>
            <Tooltip title="Update Level">
              <Button style={updateButton} onClick={handleSaveClick}>
                <SaveOutlined style={{ color: colors.primary }} />
              </Button>
            </Tooltip>

            <Tooltip title="Delete Skill">
              {" "}
              <Button
                onClick={() => handleDeleteSkill(skill.userId, skill.skillName)}
              >
                <DeleteOutlined style={{ color: "#EC4A26" }} />
              </Button>
            </Tooltip>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SkillCard;
