import React, { useState } from "react";

import CourseLayout from "../Components/KnowledgeCenter/CourseLayout";
import { wholeContainer, courseIcon } from "../Styles/BootCampStyle";
import { ReactComponent as Course } from "../assets/course.svg";
import { Typography } from "antd";

const { Title } = Typography;

export default function KnowledgeCenter() {
  const [selected, setSelected] = useState(0);

  return (
    <div style={wholeContainer}>
      <Title level={2}> Courses</Title>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <CourseLayout setSelected={setSelected} />
        <Course style={courseIcon} />
      </div>
    </div>
  );
}
