import { Menu, MenuProps } from "antd";
import React, { useState } from "react";
import ExpenseList from "./ExpenseList";
import Expense from "../../interface/Expense";

const items: MenuProps["items"] = [
  {
    label: "Pending",
    key: "pending",
  },
  {
    label: "Declined",
    key: "cancelled",
  },
  {
    label: "Approved",
    key: "approved",
  },
];

function SelfExpense({
  requestedList,
  expensesList,
  getExpenses,
  setIsLoading,
}: {
  requestedList: Expense[];
  expensesList: Expense[];
  getExpenses: () => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [current, setCurrent] = useState("pending");

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };

  return (
    <div style={{ padding: "1rem", overflowY: "auto", height: "70vh" }}>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
        style={{
          background: "none",
          width: "100%",
          fontWeight: 600,
          border: "none",
        }}
      />
      <ExpenseList
        current={current}
        expensesList={expensesList}
        requestedList={requestedList}
        getExpenses={getExpenses}
        setLoading={setIsLoading}
      />
    </div>
  );
}

export default SelfExpense;
