import React, { useEffect, useState } from "react";
import useFetch from "../../Hooks/useFetch";
import useGet from "../../Hooks/useGet";
import {
  Button,
  DatePicker,
  Input,
  Select,
  Typography,
  notification,
} from "antd";
import { InputBox } from "./InputBox";
import { NumberBox } from "./NumberBox";
import { ProfileHeader } from "./ProfileHeader";
import {
  fixedDetailsHeadingStyle,
  floatButtonStyle,
  inputBox,
  inputEditBox,
  savedDetailsStyling,
} from "../../Styles/ProfilePage/Profile";
import User, { Designation } from "../../interface/User";
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import { CheckOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { PersonalInformationData } from "../../interface/UserPersonalInformationDetails";
import { BASE_URL, dateFormatList, greytHrIdRegex } from "../../constant";
import { colors } from "../../Theme/Theme";
import { SaveOutlined } from "@ant-design/icons";

import { selectBox } from "../../Styles/ProfilePage/Landing";
import { userRoles } from "../../enums/Roles";

dayjs.extend(customParseFormat);

const PersonalInformationDetails = () => {
  const userType = localStorage.getItem("role");

  const [dateOfBirth, setDateOfBirth] = useState<string>();
  const [maritalStatus, setMaritalStatus] = useState<string>();
  const [fathersName, setFatherName] = useState<string>();
  const [mothersName, setMotherName] = useState<string>();
  const [bloodGroup, setBloodGroup] = useState<string>();
  const [mobileNumber, setMobileNumber] = useState<string>();
  const [emergencyContactNumber, setEmergencyContactNumber] =
    useState<string>();

  const [designationList, setDesignationList] = useState<Designation[]>([]);

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [greytHrId, setGreytHrId] = useState("");
  const [userDesignation, setUserDesignation] = useState("");

  const [fixedUserDetails, setFixedUserDetails] = useState<User>();

  const { Option } = Select;

  const [personalInformationData, setPersonalInformationData] =
    useState<PersonalInformationData>();

  const { userId } = useParams();

  const loggedInUser = localStorage.getItem("userId");

  const { call } = useFetch("PUT");

  const getPersonalInformationData = (data: PersonalInformationData) => {
    setPersonalInformationData(data);
    setMobileNumber(data.mobileNumber);
    setEmergencyContactNumber(data.emergencyContactNumber);
    setDateOfBirth(data.dateOfBirth);
    setMaritalStatus(data.maritalStatus);
    setFatherName(data.fathersName);
    setMotherName(data.mothersName);
    setDateOfBirth(data.dateOfBirth);
    setIsLoading(false);
  };

  const getPersonalInformation = useGet(
    `/user/${userId}/details`,
    getPersonalInformationData
  );

  const getFixedUserDetails = (data: User) => {
    setFixedUserDetails(data);
    setIsLoading(false);
  };

  const getFixedUserDetailData = useGet(`/user/${userId}`, getFixedUserDetails);

  useEffect(() => {
    setIsLoading(true);
    getPersonalInformation();
    getFixedUserDetailData();
    fetchDesignations();
  }, [getFixedUserDetailData, getPersonalInformation]);

  const setCancelHandler = () => {
    setMobileNumber(personalInformationData?.mobileNumber);
    setEmergencyContactNumber(personalInformationData?.emergencyContactNumber);
    setDateOfBirth(personalInformationData?.dateOfBirth);
    setMaritalStatus(personalInformationData?.maritalStatus);
    setFatherName(personalInformationData?.fathersName);
    setMotherName(personalInformationData?.mothersName);
    setDateOfBirth(personalInformationData?.dateOfBirth);
    setBloodGroup(personalInformationData?.bloodGroup);
  };

  const handleDateChange = (date: any, dateString: string) => {
    setDateOfBirth(dateString);
  };

  const personalDataSubmitHandler = () => {
    setIsLoading(true);
    const newPersonalInformationData = {
      userId: userId,
      dateOfBirth: dateOfBirth
        ? dateOfBirth
        : personalInformationData?.dateOfBirth,

      maritalStatus: maritalStatus
        ? maritalStatus
        : personalInformationData?.maritalStatus,

      fathersName: fathersName
        ? fathersName
        : personalInformationData?.fathersName,

      mothersName: mothersName
        ? mothersName
        : personalInformationData?.mothersName,

      bloodGroup: bloodGroup ? bloodGroup : personalInformationData?.bloodGroup,

      mobileNumber: mobileNumber
        ? mobileNumber
        : personalInformationData?.mobileNumber,

      emergencyContactNumber: emergencyContactNumber
        ? emergencyContactNumber
        : personalInformationData?.emergencyContactNumber,
    };
    if (
      (mobileNumber?.length !== 10 && mobileNumber !== null) ||
      (emergencyContactNumber?.length !== 10 && emergencyContactNumber !== null)
    ) {
      notification.error({ message: "Enter a valid 10 digit contact number" });
      setIsLoading(false);
      return;
    }

    call(
      `/user/${userId}/details`,
      newPersonalInformationData,
      () => {
        setIsLoading(false);
        notification.success({
          message: "Details added Successfully",
        });
        setIsEditable(false);
      },
      () => {
        notification.warning({
          message: "Something went wrong",
        });
      }
    );
  };

  const fetchDesignations = async () => {
    const token = localStorage.getItem("idToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await fetch(
        `${BASE_URL}/designation/all`,

        config
      );
      const data = await response.json();

      setDesignationList(data.data);
    } catch (error) {}
  };

  const handleMaritalStatusChange = (value: string) => setMaritalStatus(value);
  const handleBloodGroupChange = (value: string) => setBloodGroup(value);

  const selectDisableStyle = {
    width: "100%",
    backgroundColor: colors.background,
  };

  const selectStyle = {
    marginBottom: "1.5rem",
    width: "100%",
    backgroundColor: colors.background,
  };

  const handleSubmitGreyHrId = () => {
    if (checkGreyTHrId(greytHrId)) {
      const updateItem = {
        userId,
        greytHrId,
      };

      call(
        `/user/greythr-id`,
        updateItem,
        () => {
          setIsLoading(true);
          notification.success({
            message: "Updated GreytHr Id",
          });
          getFixedUserDetailData();
        },
        () => {
          notification.warning({
            message: "Something went wrong",
          });
        }
      );
    }
  };

  const inputStyle = {
    ...inputEditBox,
    ...savedDetailsStyling,
  };

  const options = designationList.map((designation) => {
    return {
      value: designation.designation,
      label: designation.designation,
    };
  });

  const onChange = (value: string) => {
    setUserDesignation(value);
  };

  const handleUpdateDesignation = () => {
    call(
      `/user/${userId}/designation?designation=${userDesignation}`,
      {},
      () => {
        setIsLoading(false);
        notification.success({
          message: "Updated Designation",
        });
        getFixedUserDetailData();
      },
      () => {
        notification.warning({
          message: "Something went wrong",
        });
      }
    );
  };

  const checkGreyTHrId = (value: string): boolean => {
    if (greytHrIdRegex.test(value)) {
      return true;
    } else {
      notification.error({
        message: "Enter Valid ID.",
      });
      return false;
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <Loader loading={isLoading} message="Loading Personal Details..." />
      ) : (
        <>
          <div style={{ width: "100%" }}>
            <ProfileHeader
              isEditable={isEditable}
              setIsEditable={setIsEditable}
            />
          </div>
          <div style={fixedDetailsHeadingStyle}>
            <Typography.Title level={2}>Personal Details</Typography.Title>
            <div style={{ display: "flex" }}>
              <div
                style={{ width: "18rem", height: "70vh", overflowY: "auto" }}
              >
                <Typography.Title level={4}>Name</Typography.Title>
                <Input
                  bordered={true}
                  value={fixedUserDetails?.name}
                  disabled={true}
                  style={inputBox}
                />
                <Typography.Title level={4}>Email</Typography.Title>
                <Input
                  bordered={true}
                  value={fixedUserDetails?.email}
                  disabled={true}
                  style={inputBox}
                />
                <Typography.Title level={4}>GreytHr Id</Typography.Title>
                <div>
                  <Input
                    bordered={true}
                    value={
                      greytHrId
                        ? greytHrId
                        : fixedUserDetails?.greytHrId
                        ? fixedUserDetails?.greytHrId
                        : ""
                    }
                    disabled={
                      fixedUserDetails?.greytHrId || userId !== loggedInUser
                        ? true
                        : false
                    }
                    onChange={(e) => setGreytHrId(e.target.value)}
                    placeholder="GreytHR ID"
                    style={{ ...inputBox, width: "70%", marginLeft: "0.2rem" }}
                  />
                  {userId === loggedInUser && (
                    <>
                      <Button
                        type="primary"
                        style={{ marginLeft: "1rem", height: "2.7rem" }}
                        onClick={handleSubmitGreyHrId}
                        disabled={greytHrId ? false : true}
                      >
                        <CheckOutlined />
                      </Button>
                    </>
                  )}
                </div>
                <Typography.Title level={4}>Designation</Typography.Title>
                <div>
                  <Select
                    showSearch
                    placeholder={
                      userId === loggedInUser
                        ? "Designation"
                        : "Select Designation"
                    }
                    className="search-user"
                    value={
                      userDesignation
                        ? userDesignation
                        : fixedUserDetails?.designation
                        ? fixedUserDetails?.designation.designation
                        : null
                    }
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    disabled={
                      userType === userRoles.Admin || userType === userRoles.HR
                        ? false
                        : true
                    }
                    options={options}
                    style={selectBox}
                  />
                  {(userType === userRoles.Admin ||
                    userType === userRoles.HR) && (
                    <Button
                      type="primary"
                      style={{ marginLeft: "1rem", height: "2.5rem" }}
                      onClick={handleUpdateDesignation}
                      disabled={userDesignation ? false : true}
                    >
                      <SaveOutlined />
                    </Button>
                  )}
                </div>
                <Typography.Title level={4}>Reporting to</Typography.Title>
                <Input
                  bordered={true}
                  value={
                    fixedUserDetails?.managerId != null
                      ? fixedUserDetails?.managerId
                      : ""
                  }
                  placeholder="Manager Name"
                  disabled={true}
                  style={inputBox}
                />
              </div>
              <div
                style={{
                  width: "18rem",
                  marginLeft: "10rem",
                  height: "70vh",
                  overflowY: "auto",
                }}
              >
                <Typography.Title level={4}>Mobile Number</Typography.Title>
                <NumberBox
                  data={mobileNumber}
                  isEditable={isEditable}
                  setState={setMobileNumber}
                  placeholder={"Mobile Number"}
                />

                <Typography.Title level={4}>
                  Emergency Contact Number
                </Typography.Title>
                <NumberBox
                  data={emergencyContactNumber}
                  isEditable={isEditable}
                  setState={setEmergencyContactNumber}
                  placeholder={"Emergency Contact Number"}
                />
                <Typography.Title level={4}>
                  Date Of Birth (DOB)
                </Typography.Title>
                <DatePicker
                  value={
                    dateOfBirth
                      ? dayjs(dateOfBirth, dateFormatList[0])
                      : personalInformationData?.dateOfBirth
                      ? dayjs(
                          personalInformationData?.dateOfBirth,
                          dateFormatList[0]
                        )
                      : null
                  }
                  format={dateFormatList}
                  onChange={handleDateChange}
                  placeholder={"DOB"}
                  style={!isEditable ? inputStyle : inputEditBox}
                  disabled={!isEditable}
                />
                <Typography.Title level={4}>Marital Status</Typography.Title>
                <Select
                  value={
                    maritalStatus
                      ? maritalStatus
                      : personalInformationData?.maritalStatus
                      ? personalInformationData?.maritalStatus
                      : null
                  }
                  placeholder="Select Marital Status"
                  className="search-user"
                  onChange={handleMaritalStatusChange}
                  style={!isEditable ? selectDisableStyle : selectStyle}
                  disabled={!isEditable}
                >
                  <Option value="Single">Single</Option>
                  <Option value="Married">Married</Option>
                  <Option value="Divorced">Divorced</Option>
                  <Option value="Widowed">Widowed</Option>
                </Select>
                <Typography.Title level={4} style={{ marginTop: "1rem" }}>
                  Father's Name
                </Typography.Title>
                <InputBox
                  data={fathersName}
                  isEditable={isEditable}
                  setState={setFatherName}
                  placeholder={"Father Name"}
                />
                <Typography.Title level={4}>Mother's Name</Typography.Title>
                <InputBox
                  data={mothersName}
                  isEditable={isEditable}
                  setState={setMotherName}
                  placeholder={"Mother Name"}
                />
                <Typography.Title level={4}>Blood Group</Typography.Title>
                <Select
                  defaultValue={"A+"}
                  placeholder="Select Your Blood group"
                  value={
                    bloodGroup
                      ? bloodGroup
                      : personalInformationData?.bloodGroup
                      ? personalInformationData?.bloodGroup
                      : null
                  }
                  onChange={handleBloodGroupChange}
                  style={!isEditable ? selectDisableStyle : selectStyle}
                  disabled={!isEditable}
                  className="search-user"
                >
                  <Option value="A+">A+</Option>
                  <Option value="A-">A-</Option>
                  <Option value="B+">B+</Option>
                  <Option value="B-">B-</Option>
                  <Option value="AB+">AB+</Option>
                  <Option value="AB-">AB-</Option>
                  <Option value="O+">O+</Option>
                  <Option value="O-">O-</Option>
                </Select>
              </div>
            </div>
          </div>
          {isEditable && (
            <>
              <Button
                type="primary"
                style={floatButtonStyle}
                onClick={() => {
                  personalDataSubmitHandler();
                }}
              >
                SAVE
              </Button>

              <Button
                type="primary"
                style={floatButtonStyle}
                onClick={() => {
                  setIsEditable(false);
                  setCancelHandler();
                }}
              >
                CANCEL
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PersonalInformationDetails;
