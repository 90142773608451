import React, { useState, useEffect } from "react";
import { Bar as Column } from "@ant-design/plots";
import { skillChart } from "../../Styles/SkillChartStyle";
import SkillTable from "./SkillTable";
import { BASE_URL } from "../../constant";
import { colors } from "../../Theme/Theme";
import { Skill } from "../../interface/Skill";

interface UserSkill {
  id: number;
  name: string;
  email: string;
  skill: Skill;
  level: string;
}

const SkillChart: React.FC = () => {
  const [usersData, setUsersData] = useState<UserSkill[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("idToken");
      try {
        const usersResponse = await fetch(`${BASE_URL}/user/skill/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const usersDetails = await usersResponse.json();
        setUsersData(usersDetails.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const getUserNames = (skill: Skill, level: string) => {
    const users = usersData.filter(
      (userSkill) =>
        userSkill.skill.id === skill.id && userSkill.level === level
    );
    return users.map((userSkill) => userSkill.name);
  };

  const setLevelColor = (level: string) => {
    switch (level) {
      case "BEGINNER":
        return colors.beginner;
      case "INTERMEDIATE":
        return colors.intermediate;
      case "EXPERT":
        return colors.expert;
      default:
        return colors.beginner;
    }
  };

  const chartData = usersData
    .map((userSkill: UserSkill) => {
      return {
        skill: userSkill.skill.name,
        level: userSkill.level,
        users: getUserNames(userSkill.skill, userSkill.level),
        value: 1,
      };
    })
    .sort((a, b) => {
      const levels = ["BEGINNER", "INTERMEDIATE", "EXPERT"];
      return levels.indexOf(a.level) - levels.indexOf(b.level);
    });

  const config: any = {
    data: chartData,
    yField: "skill",
    xField: "value",
    isStack: true,
    seriesField: "level",
    tooltip: {
      fields: ["users", "level"],
      title: "skill",
      showTitle: true,
      formatter: (data: any) => {
        return { name: (data.users || []).join(", "), value: data.level };
      },
    },
    color: (data: any) => setLevelColor(data.level),
  };

  return (
    <div>
      <Column style={skillChart} {...config} />
      <div>
        <SkillTable />
      </div>
    </div>
  );
};

export default SkillChart;
