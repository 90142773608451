export const navMain: React.CSSProperties = {
  maxHeight: "100vh",
  backgroundColor: "#1a1d26",
  width: "16%",
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100vh",
};

export const logo: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: "1.5rem",
  fontWeight: "600",
  color: "white",
  paddingBottom: "2.5rem",
  paddingTop: "1.5rem",
  textAlign: "center",
};

export const navItems: React.CSSProperties = {
  height: "5vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  paddingLeft: "0.5rem",
  borderRadius: "4px",
  marginTop: "1rem",
  fontSize: "0.9em",
  cursor: "pointer",
  color: "#989CA5",
  fontWeight: 600,
  letterSpacing: 1.2,
};

export const navLogo: React.CSSProperties = {
  marginRight: "1rem",
  marginLeft: "0.5rem",
  fontSize: "1.5rem",
};
