import { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  token: {
    colorPrimary: "#007373",
  },
};

export const colors = {
  header: "#1a1d26",
  background: "#fff",
  addTag: "#00b3b3",
  innerBackground: "#FBFAFE",
  beginner: "#ADCFF2",
  intermediate: "#6199D7",
  expert: "#114179",
  primary: "#007373",
  innerBackgroundDark: "#555",
  innerBackgroundLight: "#444",
};

export default theme;
