import { Card } from "antd";
import React from "react";
import Video from "../../interface/Video";
import { mainVideoCardStyle } from "../../Styles/BootCampStyle";

export default function VideoCard({ data }: { data: Video }) {
  return (
    <div style={mainVideoCardStyle}>
      <Card size="small" hoverable>
        <h4>{data.heading}</h4>
        <iframe src={data.url} allowFullScreen></iframe>
      </Card>
    </div>
  );
}
