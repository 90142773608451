import React, { useEffect, useState } from "react";
import useGet from "../../Hooks/useGet";
import { pdfBox, pdfIcon, videoCardStyling } from "../../Styles/BootCampStyle";
import VideoCard from "./VideoCard";
import { useParams } from "react-router-dom";
import ResourceList from "./ResourceList";
import Video from "../../interface/Video";
import { Pdf } from "../../interface/Pdf";
import { ReactComponent as LandingGraphic } from "../../assets/pdf.svg";

import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { Input } from "antd";
import {
  FloatButton,
  Modal,
  Button,
  UploadProps,
  notification,
  UploadFile,
  message,
} from "antd";
import useFetch from "../../Hooks/useFetch";
import Dragger from "antd/es/upload/Dragger";
import { RcFile } from "antd/es/upload/interface";
import Loader from "../Loader";
import { BASE_URL, fileType } from "../../constant";
import { colors } from "../../Theme/Theme";
import { Typography } from "antd";

const { Title } = Typography;

export default function Resources() {
  const { id } = useParams();
  const [video, setVideo] = useState<Video[]>([]);
  const [tab, setTab] = useState("video");
  const [pdf, setPdf] = useState<Pdf[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [heading, setHeading] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOkPdf = () => {
    setIsLoading(true);
    const token = localStorage.getItem("idToken");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });
    formData.append("name", name);
    formData.append("courseId", id!!);
    fetch(`${BASE_URL}/course/courseId/pdf`, {
      method: "POST",
      body: formData,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then(() => {
        setIsLoading(false);
        setFileList([]);
        setName("");
        setIsModalOpen(false);
        getPdfOfSelectedCourse();
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {});
  };

  const getVideoData = (video: Video[]) => {
    if (video) setVideo(video);
    setIsLoading(false);
  };

  const getVideoOfSelectedCourse = useGet(`/course/${id}/videos`, getVideoData);

  const getPdfData = (pdf: Pdf[]) => {
    if (pdf) setPdf(pdf);
    setIsLoading(false);
  };

  const getPdfOfSelectedCourse = useGet(`/course/${id}/pdf`, getPdfData);

  useEffect(() => {
    setIsLoading(true);
    getVideoOfSelectedCourse();
    getPdfOfSelectedCourse();
  }, [getPdfOfSelectedCourse, getVideoOfSelectedCourse, id]);

  const { call } = useFetch("POST");

  const submit = () => {
    setIsLoading(true);

    const videoDetails = {
      heading,
      url,
    };
    let substring = "embed";
    if (!url.includes(substring, 0)) {
      notification.error({ message: "Invalid, Enter Embedded URL" });
      getVideoOfSelectedCourse();
      return;
    }
    call(
      `/course/${id}/video`,
      videoDetails,
      (data) => {
        setIsLoading(false);
        setIsModalOpen(false);
        getVideoOfSelectedCourse();
      },
      (error) => {}
    );
  };
  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (file.type === fileType) {
        setFileList([...fileList, file]);
      } else {
        notification.error({ message: "Wrong File type." });
      }
      return false;
    },
    fileList,
    onDrop(e) {
      notification.error({
        message: "DroppedFiles",
        description: "e.dataTransfer.files",
      });
    },
  };

  return (
    <div>
      <div className="Tabs">
        <ul className="nav">
          <li
            className={activeTab === "tab1" ? "active" : ""}
            onClick={() => {
              setTab("video");
              setActiveTab("tab1");
            }}
          >
            Videos
          </li>
          <li
            className={activeTab === "tab2" ? "active" : ""}
            onClick={() => {
              setTab("pdf");
              setActiveTab("tab2");
            }}
          >
            Pdf
          </li>
        </ul>
      </div>
      {tab === "video" && (
        <>
          {isLoading ? (
            <Loader loading={isLoading} message="Loading Videos.." />
          ) : (
            <div style={videoCardStyling}>
              {video.length > 0 ? (
                video.map((data, index) => {
                  return <VideoCard data={data} key={index} />;
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "18rem",
                  }}
                >
                  <Title level={4}>No Videos Uploaded yet</Title>
                </div>
              )}
              <FloatButton
                style={{ background: colors.addTag }}
                tooltip={<div>Add New Video</div>}
                icon={<PlusOutlined />}
                onClick={showModal}
              />

              <Modal
                style={{ top: "50px" }}
                title="Add New Video"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={
                  <Button
                    key="submit"
                    type="primary"
                    onClick={submit}
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    Save
                  </Button>
                }
              >
                <Input
                  placeholder="Video Title"
                  style={{ marginTop: "0.5rem", width: "100%" }}
                  onChange={(e) => setHeading(e.target.value)}
                />
                <Input
                  placeholder="Video URL (Embedded URL only)"
                  style={{ marginTop: "0.5rem", width: "100%" }}
                  onChange={(e) => setUrl(e.target.value)}
                />
              </Modal>
            </div>
          )}
        </>
      )}
      {tab === "pdf" && (
        <>
          {isLoading ? (
            <Loader loading={isLoading} message="Loading PDF" />
          ) : (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={pdfBox}>
                <div>
                  {pdf.length > 0 ? (
                    pdf.map((data, index) => {
                      return (
                        <>
                          <ResourceList
                            data={data}
                            key={index}
                            setLoading={setIsLoading}
                          />
                          <div
                            style={{
                              height: 1,
                              background: "#e5f2f2",
                              width: "90%",
                              margin: "auto",
                            }}
                          />
                        </>
                      );
                    })
                  ) : (
                    <Title level={4}>No Pdfs for this Course.</Title>
                  )}
                </div>

                <FloatButton
                  style={{ background: colors.addTag }}
                  tooltip={<div>Add New Pdf</div>}
                  icon={<PlusOutlined />}
                  onClick={showModal}
                />

                <Modal
                  style={{ top: "50px" }}
                  title="Add New Pdf"
                  open={isModalOpen}
                  onCancel={handleCancel}
                  footer={
                    <Button
                      key="submit"
                      type="primary"
                      onClick={handleOkPdf}
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      Save
                    </Button>
                  }
                >
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click to select file to be uploaded
                    </p>
                    <p className="ant-upload-hint">(*.pdf file upto 5mb)</p>
                  </Dragger>
                  <Input
                    placeholder="PDF Title"
                    style={{ marginTop: "0.5rem", width: "100%" }}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Modal>
              </div>
              <LandingGraphic style={pdfIcon} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
