export const mainOnboarding: React.CSSProperties = {
  display: "flex",
  marginTop: "1rem",
};

export const form: React.CSSProperties = {
  backgroundColor: "#e5f2f2",
  border: "1px solid #cce5e5",
  borderRadius: "10px",
  flex: 2,
  marginRight: "1rem",
  paddingLeft: "3rem",
  paddingRight: "3rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  height: "70vh",
  overflowY: "auto",
};

export const floatButtonStyles: React.CSSProperties = {
  right: 94,
  width: "10vw",
};
