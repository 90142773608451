import React, { useEffect, useState } from "react";
import { AutoComplete, Button, notification, Select, SelectProps } from "antd";

import SkillCard from "./SkillCard";
import { BASE_URL } from "../../constant";
import {
  addNewSkillHeading,
  addSkillDiv,
  addSkillStyle,
  autoComplete,
  cardContainer,
  iconStyle,
  pageTitle,
  selectLevel,
  titleText,
} from "../../Styles/ProfilePage/SkillDetailsStyle";
import { LineChartOutlined } from "@ant-design/icons";
import {
  AddNewSkill,
  DeleteSkill,
  NewSkill,
  UpdateLevel,
  UserSkill,
} from "../../interface/Skill";

const SkillDetails: React.FC = () => {
  const [skills, setSkills] = useState<UserSkill[] | null>(null);
  const [selectedSkillName, setSelectedSkillName] = useState("");
  const [selectedSkillLevel, setSelectedSkillLevel] = useState("");
  const [allSkills, setAllSkills] = useState<NewSkill[]>([]);
  const [options, setOptions] = useState<{ value: string }[]>([]);

  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("idToken");

  const fetchSkills = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user/${userId}/skill/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      const sortedSkills = data.data.sort((a: UserSkill, b: UserSkill) => {
        if (a.level === "EXPERT" && b.level !== "EXPERT") {
          return -1;
        } else if (a.level !== "EXPERT" && b.level === "EXPERT") {
          return 1;
        } else if (a.level === "INTERMEDIATE" && b.level === "BEGINNER") {
          return -1;
        } else if (a.level === "BEGINNER" && b.level !== "BEGINNER") {
          return 1;
        }
        return 0;
      });
      setSkills(sortedSkills);
    } catch (error) {}
  };

  const fetchAllSkills = async () => {
    try {
      const response = await fetch(`${BASE_URL}/skill/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setAllSkills(data.data);
    } catch (error) {}
  };
  useEffect(() => {
    setOptions(
      allSkills.map((skill) => ({
        value: skill.name,
      }))
    );
  }, [allSkills]);

  useEffect(() => {
    fetchSkills();
    fetchAllSkills();
  }, []);

  const handleAddSkill = async () => {
    try {
      const selectedSkillObj = allSkills.find(
        (skill) => skill.name === selectedSkillName
      );

      if (!selectedSkillObj) {
        throw new Error("Selected skill not found");
      }
      const skillExists = skills?.find(
        (skill) => skill.skillName === selectedSkillObj.name
      );

      if (skillExists) {
        notification.error({ message: "Skill already exists" });
        return;
      }

      const newSkill: AddNewSkill = {
        id: Number(userId),
        skillName: selectedSkillObj.name,
        level: selectedSkillLevel,
      };
      if (selectedSkillLevel === "") {
        notification.error({ message: "Select the level of proficiency" });
      }

      const response = await fetch(`${BASE_URL}/user/${userId}/skill`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSkill),
      });

      if (response.ok) {
        notification.success({ message: "Skill Added Successfully" });
        fetchSkills();
        setSelectedSkillName("");
        setSelectedSkillLevel("");
      } else {
      }
    } catch (error) {}
  };

  const handleDeleteSkill = async (userId: number, skillName: string) => {
    const deleteSkill: DeleteSkill = {
      id: Number(userId),
      skillName: skillName,
    };
    const Url = `${BASE_URL}/user/${userId}/skill`;

    try {
      const response = await fetch(Url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(deleteSkill),
      });

      if (response.ok) {
        notification.success({ message: "Skill Deleted Successfully" });
        fetchSkills();
      }
    } catch (error) {}
  };

  const handleSave = async (userId: number, skillId: number, level: string) => {
    try {
      const skill = skills?.find((skill) => skill.skillId === skillId);
      if (skill?.level === level) {
        notification.info({
          message: "Selected level is the same as the existing level",
        });
        return;
      }
      const updateLevel: UpdateLevel = {
        id: Number(userId),
        level: level,
        skillName: skill?.skillName,
      };

      const url = `${BASE_URL}/user/${userId}/skill`;

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateLevel),
      });
      if (response.ok) {
        notification.success({ message: "Level Updated Successfully" });
        fetchSkills();
      } else {
      }
    } catch (error) {}
  };

  const onSearch = (searchText: string) => {
    if (!searchText) {
      setOptions(
        allSkills.map((skill) => ({
          value: skill.name,
        }))
      );
      return;
    }

    const filteredSkills = allSkills.filter((skill) =>
      skill.name.toLowerCase().startsWith(searchText.toLowerCase())
    );

    const formattedOptions = filteredSkills.map((skill) => ({
      value: skill.name,
    }));

    setOptions(formattedOptions);
  };

  const onSkillChange = (data: string) => {
    setSelectedSkillName(data);
  };
  const levelOptions: SelectProps["options"] = [];

  levelOptions.push({
    value: "BEGINNER",
    label: "BEGINNER",
  });
  levelOptions.push({
    value: "INTERMEDIATE",
    label: "INTERMEDIATE",
  });
  levelOptions.push({
    value: "EXPERT",
    label: "EXPERT",
  });
  if (skills?.length === 0) {
    return (
      <div style={addSkillDiv}>
        <div>
          <h3 style={addNewSkillHeading}>Add New Skill: </h3>
        </div>
        <div>
          <AutoComplete
            value={selectedSkillName}
            options={options}
            style={autoComplete}
            className="search-user"
            onSearch={onSearch}
            onChange={onSkillChange}
            placeholder="Search Skills "
          />
        </div>
        <div>
          <Select
            style={selectLevel}
            className="search-user"
            value={selectedSkillLevel ? selectedSkillLevel : null}
            placeholder="Select Level"
            onChange={(value) => setSelectedSkillLevel(value.toString())}
          >
            <Select.Option value="BEGINNER">BEGINNER</Select.Option>
            <Select.Option value="INTERMEDIATE">INTERMEDIATE</Select.Option>
            <Select.Option value="EXPERT">EXPERT</Select.Option>
          </Select>
        </div>
        <div style={addSkillStyle}>
          <Button type="primary" onClick={handleAddSkill}>
            Add
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={addSkillDiv}>
        <div>
          <h3 style={addNewSkillHeading}>Add New Skill: </h3>
        </div>
        <div>
          <AutoComplete
            value={selectedSkillName}
            options={options}
            style={autoComplete}
            className="search-user"
            onSearch={onSearch}
            onChange={onSkillChange}
            placeholder="Search Skills "
          />
        </div>
        <div>
          <Select
            style={selectLevel}
            className="search-user"
            value={selectedSkillLevel ? selectedSkillLevel : null}
            placeholder="Select Level"
            onChange={(value) => setSelectedSkillLevel(value.toString())}
          >
            <Select.Option value="BEGINNER">BEGINNER</Select.Option>
            <Select.Option value="INTERMEDIATE">INTERMEDIATE</Select.Option>
            <Select.Option value="EXPERT">EXPERT</Select.Option>
          </Select>
        </div>
        <div style={addSkillStyle}>
          <Button type="primary" onClick={handleAddSkill}>
            Add
          </Button>
        </div>
      </div>
      <div style={pageTitle}>
        <h2 style={titleText}>
          Your Skills <LineChartOutlined style={iconStyle} />
        </h2>
      </div>
      <div style={cardContainer}>
        {skills?.map((skill) => (
          <SkillCard
            key={skill.skillId}
            skill={skill}
            handleSave={handleSave}
            handleDeleteSkill={handleDeleteSkill}
          />
        ))}
      </div>
    </div>
  );
};

export default SkillDetails;
