import React, { useState } from "react";
import { innerContainer } from "../Styles/HeaderStyle";
import TableView from "./OrganizationView/TableView";
import SkillChart from "./OrganizationView/SkillChart";
export default function OrganizationPage() {
  const [tab, setTab] = useState("tableView");
  const [activeTab, setActiveTab] = useState("tableView");

  return (
    <div style={innerContainer}>
      <br />
      <ul className="nav">
        <li
          className={activeTab === "tableView" ? "active" : ""}
          onClick={() => {
            setTab("tableView");
            setActiveTab("tableView");
          }}
        >
          Grid View
        </li>
        <li
          className={activeTab === "skillChart" ? "active" : ""}
          onClick={() => {
            setTab("skillChart");
            setActiveTab("skillChart");
          }}
        >
          Skill Chart
        </li>
      </ul>
      {tab === "tableView" && <TableView />}
      {tab === "skillChart" && <SkillChart />}
    </div>
  );
}
