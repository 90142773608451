import React, { useEffect, useState } from "react";
import useFetch from "../../Hooks/useFetch";
import useGet from "../../Hooks/useGet";
import { Button, Typography, notification } from "antd";
import { ProfileHeader } from "./ProfileHeader";
import {
  floatButtonStyle,
  profileSections,
} from "../../Styles/ProfilePage/Profile";
import { NumberBox } from "./NumberBox";
import Loader from "../Loader";
import { AddressData } from "../../interface/UserAddress";
import { Input } from "antd";

const { TextArea } = Input;

const AddressDetails = () => {
  const [currentAddress, setCurrentAddress] = useState<string>();
  const [currentPincode, setCurrentPincode] = useState<string>();
  const [permanentAddress, setPermanentAddress] = useState<string>();
  const [permanentPincode, setPermanentPincode] = useState<string>();

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [addressData, setAddressData] = useState<AddressData>();

  const userId = localStorage.getItem("userId");

  const { call } = useFetch("PUT");

  const getAddressData = (data: AddressData) => {
    setAddressData(data);
    setCurrentAddress(data.currentAddress);
    setCurrentPincode(data.currentPincode);
    setPermanentAddress(data.permanentAddress);
    setPermanentPincode(data.permanentPincode);
    setIsLoading(false);
  };

  const getAddressDetails = useGet(`/user/${userId}/address`, getAddressData);

  useEffect(() => {
    setIsLoading(true);
    getAddressDetails();
  }, []);

  const setCancelHandler = () => {
    setCurrentAddress(addressData?.currentAddress);
    setCurrentPincode(addressData?.currentPincode);
    setPermanentAddress(addressData?.permanentAddress);
    setPermanentPincode(addressData?.currentPincode);
  };

  const addressDataSubmitHandler = () => {
    setIsLoading(true);
    const newAddressData = {
      userId: userId,
      currentAddress: currentAddress
        ? currentAddress
        : addressData?.currentAddress,
      currentPincode: currentPincode
        ? currentPincode
        : addressData?.currentPincode,
      permanentAddress: permanentAddress
        ? permanentAddress
        : addressData?.permanentAddress,
      permanentPincode: permanentPincode
        ? permanentPincode
        : addressData?.permanentPincode,
    };
    if (
      (currentPincode?.length !== 6 && currentPincode !== null) ||
      (permanentPincode?.length !== 6 && permanentPincode !== null)
    ) {
      notification.error({ message: "Enter a valid Pincode" });
      getAddressDetails();
      return;
    }
    call(
      `/user/${userId}/address`,
      newAddressData,
      () => {
        setIsLoading(false);
        notification.success({
          message: "User Addreess Details Added",
        });
      },
      () => {
        notification.warning({
          message: "Something went wrong",
        });
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} message="Loading Address Details.." />
      ) : (
        <div style={{ width: "100%", overflowY: "auto", height: "90vh" }}>
          <div style={{ width: "100%" }}>
            <ProfileHeader
              isEditable={isEditable}
              setIsEditable={setIsEditable}
            />
          </div>
          <div style={profileSections}>
            <Typography.Title level={2}>Address</Typography.Title>
            <Typography.Title level={4}>Current Address</Typography.Title>
            <TextArea
              value={currentAddress}
              disabled={!isEditable}
              onChange={(e) => setCurrentAddress(e.target.value)}
              placeholder={"Current Address"}
              autoSize={{ minRows: 2, maxRows: 4 }}
              className="address-field"
              allowClear
              style={{
                backgroundColor: isEditable ? "#fff" : "#fbfafe",
                marginBottom: "1rem",
              }}
            />
            <Typography.Title level={4}>Current Pincode</Typography.Title>
            <NumberBox
              data={currentPincode}
              isEditable={isEditable}
              setState={setCurrentPincode}
              placeholder={"Current Pincode"}
            />
            <Typography.Title level={4}>Permanent Address</Typography.Title>
            <TextArea
              value={permanentAddress}
              disabled={!isEditable}
              onChange={(e) => setPermanentAddress(e.target.value)}
              placeholder={"Permanent Address"}
              autoSize={{ minRows: 2, maxRows: 4 }}
              className="address-field"
              allowClear
              style={{
                marginBottom: "1rem",
              }}
            />
            <Typography.Title level={4}>Permanent Pincode</Typography.Title>
            <NumberBox
              data={permanentPincode}
              isEditable={isEditable}
              setState={setPermanentPincode}
              placeholder={"Permanent Pincode"}
            />
          </div>
          {isEditable && (
            <>
              <Button
                type="primary"
                style={floatButtonStyle}
                onClick={() => {
                  setIsEditable(false);
                  addressDataSubmitHandler();
                  setIsLoading(true);
                }}
              >
                SAVE
              </Button>

              <Button
                type="primary"
                style={floatButtonStyle}
                onClick={() => {
                  setIsEditable(false);
                  setCancelHandler();
                }}
              >
                CANCEL
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AddressDetails;
