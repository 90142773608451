import React from "react";
import { expenseList } from "../../Styles/ExpensePage/ExpenseStyles";
import ExpenseItem from "./ExpenseItem";
import Expense from "../../interface/Expense";

export interface ExpenseResponse {
  self: Expense[];
  memberList: Expense[];
}

function ExpenseList({
  current,
  requestedList,
  expensesList,
  getExpenses,
  setLoading,
}: {
  current: string;
  requestedList: Expense[];
  expensesList: Expense[];
  getExpenses: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div style={expenseList}>
      {current === "requested" ? (
        requestedList.length > 0 ? (
          requestedList.map((expense, idx) => {
            return (
              <ExpenseItem
                key={idx}
                expense={expense}
                access="manage"
                status={expense.status}
                setLoader={setLoading}
                getExpenses={getExpenses}
              />
            );
          })
        ) : (
          <h3>No Reimbursement Requests</h3>
        )
      ) : expensesList.filter((e) => e.status === { current }.current).length >
        0 ? (
        expensesList
          .filter((e) => e.status === { current }.current)
          .map((expense, idx) => {
            return (
              <ExpenseItem
                key={idx}
                expense={expense}
                access="self"
                status={expense.status}
                setLoader={setLoading}
                getExpenses={getExpenses}
              />
            );
          })
      ) : (
        <h3>No {current} Requests</h3>
      )}
    </div>
  );
}

export default ExpenseList;
