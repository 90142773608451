import { colors } from "../Theme/Theme";

export const skillTable: React.CSSProperties = {
  overflowX: "auto",
  padding: "10px 5px",
};

export const levelMap: React.CSSProperties = {
  borderSpacing: "0 10px",
  padding: "20px",
  border: "1px solid #014d4e",
  borderCollapse: "revert-layer",
};

export const tableHeading1: React.CSSProperties = {
  border: "1px solid #cce5e5",
  padding: "4px 10px",
  fontSize: 12,
  width: 40,
  fontWeight: "bold",
  fontFamily: "Arial, sans-serif",
  textAlign: "center",
  color: "#014d4e",
};
export const tableHeading2: React.CSSProperties = {
  border: "1px solid #cce5e5",
  padding: "4px 10px",
  color: colors.beginner,
  fontWeight: "bold",
  fontSize: 13,
  fontFamily: "Arial, sans-serif",
  textAlign: "center",
};
export const tableHeading3: React.CSSProperties = {
  border: "1px solid #cce5e5",
  padding: "4px 10px",
  color: colors.intermediate,
  fontSize: 13,
  fontWeight: "bold",
  fontFamily: "Arial, sans-serif",
  textAlign: "center",
};
export const tableHeading4: React.CSSProperties = {
  border: "1px solid #cce5e5",
  padding: "4px 10px",
  color: colors.expert,
  fontSize: 13,
  fontWeight: "bold",
  fontFamily: "Arial, sans-serif",
  textAlign: "center",
};
