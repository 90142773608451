import {
  Button,
  DatePicker,
  DatePickerProps,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useState } from "react";
import {
  dateInput,
  headerInput,
  mainContainer,
} from "../../Styles/LeavesCalendar/ReportStyles";
import { Report } from "../../interface/Report";
import useGet from "../../Hooks/useGet";
import Loader from "../Loader";
import { ColumnsType } from "antd/es/table";

const columns: ColumnsType<Report> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Leaves",
    dataIndex: "leavesDates",
    key: "leavesDates",
    render: (data: string[]) => (
      <Tooltip title={data.toString().replaceAll(",", " , ")}>
        {data.length}
      </Tooltip>
    ),
  },
  {
    title: "Work From Homes",
    dataIndex: "workFromHomeDates",
    key: "workFromHomeDates",
    render: (data: string[]) => (
      <Tooltip title={data.toString().replaceAll(",", " , ")}>
        {data.length}
      </Tooltip>
    ),
  },
];

function convertData(data: Report[]): Report[] {
  const newData = data.map((leave) => {
    const res: Report = {
      name: leave.name,
      leaves: leave.leaves,
      workFromHomes: leave.workFromHomes,
      leavesDates: leave.leavesDates.map((date) => {
        const newDate = new Date(date);
        return newDate.toString().slice(4, 15);
      }),
      workFromHomeDates: leave.workFromHomeDates.map((date) => {
        const newDate = new Date(date);
        return newDate.toString().slice(4, 15);
      }),
    };
    return res;
  });
  console.log("check2", newData);
  return newData;
}

function LeavesReport() {
  const { Title } = Typography;

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportData, setReportData] = useState<Report[]>([]);
  const [loading, setLoading] = useState(false);

  const setData = (data: Report[]) => {
    if (data) {
      console.log("check", data);
      const newData = convertData(data);
      console.log(newData);
      console.log("here");
      setReportData(newData);
      setLoading(false);
    }
  };
  const getReport = useGet(
    `/calendar/report?start=${fromDate}&end=${toDate}`,
    setData
  );

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setFromDate(dateString);
  };

  const onToDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setToDate(dateString);
  };

  const handleClick = () => {
    setLoading(true);
    getReport();
  };

  return (
    <>
      {loading ? (
        <Loader loading message="Loading Leaves Report" />
      ) : (
        <div style={mainContainer}>
          <Title level={2}>Leaves Report</Title>
          <div style={headerInput}>
            <div style={dateInput}>
              <Title style={{ marginTop: "0.5rem" }} level={4}>
                Select From Date :
              </Title>
              <DatePicker onChange={onChange} />
            </div>
            <div style={dateInput}>
              <Title style={{ marginTop: "0.5rem" }} level={4}>
                Select To Date :
              </Title>
              <DatePicker onChange={onToDateChange} />
            </div>
            <Button
              type="primary"
              onClick={handleClick}
              disabled={fromDate.length > 0 && toDate.length > 0 ? false : true}
            >
              Get Report
            </Button>
          </div>
          {reportData.length > 0 ? (
            <Table
              dataSource={reportData}
              columns={columns}
              size="small"
              scroll={{ y: 350 }}
              pagination={false}
              bordered
              style={{ marginTop: "1rem" }}
            ></Table>
          ) : (
            <Title level={5} style={{ marginTop: "1rem" }}>
              Select Range of Dates
            </Title>
          )}
        </div>
      )}
    </>
  );
}

export default LeavesReport;
