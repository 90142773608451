import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { GiOrganigram } from "react-icons/gi";
import { GoHome } from "react-icons/go";
import { MdOutlinePayments } from "react-icons/md";
import { TbSchool } from "react-icons/tb";
import { BsPersonWorkspace } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { logo, navItems, navLogo, navMain } from "../Styles/NavbarStyles";
import { BiCalendarExclamation } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { colors } from "../Theme/Theme";

const Navbar = () => {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  return (
    <div style={navMain}>
      <div style={logo}>Studio Central</div>
      <hr style={{ marginLeft: "1rem", marginRight: "1rem" }} />
      <div style={{ cursor: "pointer", overflowY: "auto", height: "70vh" }}>
        <div
          style={
            path === "/"
              ? {
                  ...navItems,
                  color: colors.background,
                  marginTop: "4rem",
                }
              : { ...navItems, marginTop: "4rem" }
          }
          className={path === "/" ? "nav-active" : ""}
          onClick={() => {
            navigate("/");
          }}
        >
          <GoHome style={navLogo} />
          Home
        </div>
        <div
          style={
            path === "/organizationPage"
              ? { ...navItems, color: colors.background }
              : navItems
          }
          className={path === "/organizationPage" ? "nav-active" : ""}
          onClick={() => {
            navigate("/organizationPage");
          }}
        >
          <GiOrganigram style={navLogo} />
          Organization
        </div>
        <div
          style={
            path === "/leaves"
              ? { ...navItems, color: colors.background }
              : navItems
          }
          className={path === "/leaves" ? "nav-active" : ""}
          onClick={() => {
            navigate("/leaves");
          }}
        >
          <BiCalendarExclamation style={navLogo} />
          Leaves
        </div>
        <div
          style={
            path === "/project"
              ? { ...navItems, color: colors.background }
              : navItems
          }
          className={path === "/project" ? "nav-active" : ""}
          onClick={() => {
            navigate("/project");
          }}
        >
          <BsPersonWorkspace style={navLogo} />
          Project
        </div>

        <div
          style={
            path === "/bills"
              ? { ...navItems, color: colors.background }
              : navItems
          }
          className={path === "/bills" ? "nav-active" : ""}
          onClick={() => {
            navigate("/bills");
          }}
        >
          <MdOutlinePayments style={navLogo} />
          Reimbursements
        </div>
        <div
          style={
            path === "/knowledgeCenter"
              ? { ...navItems, color: colors.background }
              : navItems
          }
          className={path === "/knowledgeCenter" ? "nav-active" : ""}
          onClick={() => {
            navigate("/knowledgeCenter");
          }}
        >
          <TbSchool style={navLogo} />
          Knowledge Center
        </div>
      </div>
      <div
        style={{
          cursor: "pointer",
        }}
      >
        <div
          style={
            path === `/profile/${userId}`
              ? { ...navItems, color: colors.background }
              : navItems
          }
          className={path === `/profile/${userId}` ? "nav-active" : ""}
          onClick={() => {
            navigate(`/profile/${userId}`);
          }}
        >
          <CgProfile style={navLogo} />
          Profile
        </div>
        <div
          style={navItems}
          onClick={() => {
            localStorage.clear();
            navigate("/login");
          }}
        >
          <MdLogout style={navLogo} />
          Logout
        </div>
      </div>
    </div>
  );
};

export default Navbar;
