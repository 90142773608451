import { useCallback } from "react";
import { BASE_URL } from "../constant";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

function useGet(apiPath: string, getData: any) {
  const navigate = useNavigate();
  const token = localStorage.getItem("idToken");
  const doGet = useCallback(() => {
    try {
      fetch(`${BASE_URL}${apiPath}`, {
        method: "GET",
        headers: token
          ? {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            }
          : {},
      })
        .then((res) => {
          if (res.status === 403) navigate("/session-error");
          if (res.status === 401) {
            navigate("/login");
            throw new Error("UnAuthorized");
          }
          return res.json();
        })
        .then((data) => {
          getData(data.data);
        })
        .catch((error) => {
          notification.error({
            message: "Something went wrong.",
          });
        });
    } catch (error) {}
  }, [apiPath, token, navigate]);

  return doGet;
}
export default useGet;
