import React, { FC } from "react";
import { Input } from "antd";

import {
  inputEditBox,
  savedDetailsStyling,
} from "../../Styles/ProfilePage/Profile";
import { regex } from "../../constant";

interface NumberBoxProps {
  data: string | undefined;
  isEditable: boolean;
  setState: React.Dispatch<React.SetStateAction<string | undefined>>;
  placeholder: string;
}

export const NumberBox: FC<NumberBoxProps> = ({
  data,
  isEditable,
  setState,
  placeholder,
}) => {
  const inputStyle = {
    ...inputEditBox,
    ...savedDetailsStyling,
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = e.target.value;
    const reg = regex;
    if (
      inputValue === "" ||
      reg.test(String(inputValue)) ||
      inputValue === "0"
    ) {
      setState(inputValue);
    }
  };

  return (
    <div>
      <Input
        value={data}
        style={!isEditable ? inputStyle : inputEditBox}
        required
        disabled={!isEditable}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};
