import React, { useEffect, useState } from "react";
import useGet from "../../Hooks/useGet";
import Course from "../../interface/Course";
import { courseLayoutStyle } from "../../Styles/BootCampStyle";
import CourseList from "./CourseList";
import { Button, FloatButton, Input, Modal, notification } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import useFetch from "../../Hooks/useFetch";
import Loader from "../Loader";
import { colors } from "../../Theme/Theme";
import { Typography } from "antd";

const { Title } = Typography;

export default function CourseLayout({
  setSelected,
}: {
  setSelected: React.Dispatch<React.SetStateAction<number>>;
}) {
  const userId = localStorage.getItem("userId");
  const [course, setCourse] = useState<Course[]>([]);
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);

  const handleCancel = () => {
    setIsModalOpen(false);
    setName("");
    setDescription("");
    setDuration("");
  };

  const getData = (course: Course[]) => {
    if (course) setCourse(course);
    setIsLoading(false);
    setName("");
    setDescription("");
    setDuration("");
  };

  const getCourses = useGet(`/course/all`, getData);

  useEffect(() => {
    setIsLoading(true);
    getCourses();
  }, [getCourses]);

  const { call } = useFetch("POST");

  const submit = () => {
    setIsLoading(true);

    const courseDetails = {
      name,
      description,
      createdBy: userId,
      duration,
    };
    if (name === "" || description === "" || duration === "") {
      notification.warning({ message: "Fields can't be empty" });
      getCourses();
      return;
    }
    call(
      "/course",
      courseDetails,
      (data) => {
        setIsModalOpen(false);
        setIsLoading(false);
        getCourses();
      },
      (error) => {
        notification.warning({
          message: "Failed",
          description: "Failed to add the courses",
        });
      }
    );
  };

  return (
    <>
      {" "}
      {isLoading ? (
        <Loader loading={isLoading} message="Loading Courses.." />
      ) : (
        <div style={courseLayoutStyle}>
          {course.length > 0 ? (
            course.map((data) => {
              return (
                <CourseList
                  key={data.id}
                  data={data}
                  setSelected={setSelected}
                />
              );
            })
          ) : (
            <Title level={4}>No course to show</Title>
          )}
          <FloatButton
            style={{ background: colors.addTag }}
            tooltip={<div>Add New Course</div>}
            icon={<PlusOutlined />}
            onClick={showModal}
          />

          <Modal
            style={{ top: "50px" }}
            title="Add New Course"
            open={isModalOpen}
            onCancel={handleCancel}
            destroyOnClose={true}
            footer={
              <Button
                key="submit"
                type="primary"
                onClick={submit}
                style={{ marginTop: "1rem" }}
              >
                Save
              </Button>
            }
          >
            <Input
              placeholder="Course Name"
              style={{ marginTop: "0.5rem", width: "100%" }}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              placeholder="Course Description"
              style={{ marginTop: "0.5rem", width: "100%" }}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Input
              placeholder="Course Duration (Enter number of hours)"
              style={{ marginTop: "0.5rem", width: "100%" }}
              onChange={(e) => setDuration(e.target.value)}
            />
          </Modal>
        </div>
      )}
    </>
  );
}
