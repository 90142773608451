import React from "react";
import { Drawer, Tag, notification } from "antd";
import { CloseCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { UserDetails, UserSkill } from "../../interface/User";
import { colors } from "../../Theme/Theme";
import {
  designationStyle,
  emailDiv,
  hrStyle,
  infoDiv,
  phoneDiv,
  projectInfo,
  skillInfo,
} from "../../Styles/SidebarStyle";
import { UserProjectDetails } from "../../interface/AllProject";

interface SidebarProps {
  data: UserDetails;
  onClose: () => void;
}

function SidebarDrawer({ data, onClose }: SidebarProps) {
  const copyEmail = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    const email = data.email;

    if (email) {
      navigator.clipboard.writeText(email).then(() => {
        notification.success({ message: "Email copied to Clipboard" });
      });
    }
  };

  const copyPhone = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    const phone = data?.mobileNumber;

    if (phone) {
      navigator.clipboard.writeText(phone).then(() => {
        notification.success({ message: "Contact No. copied to Clipboard" });
      });
    }
  };

  const userSkills = data?.skills as UserSkill[] | null;

  const getLevelColor = (level: string) => {
    switch (level) {
      case "BEGINNER":
        return colors.beginner;
      case "INTERMEDIATE":
        return colors.intermediate;
      case "EXPERT":
        return colors.expert;
      default:
        return colors.beginner;
    }
  };

  const getLevelCase = (level: string) => {
    switch (level) {
      case "BEGINNER":
        return "Beginner";
      case "INTERMEDIATE":
        return "Intermediate";
      case "EXPERT":
        return "Expert";
      default:
        return "";
    }
  };

  const userProjects = data?.projects as UserProjectDetails[] | null;

  return (
    <Drawer
      title={
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {data.name}
            <CloseCircleOutlined
              style={{
                fontSize: "1rem",
                cursor: "pointer",
              }}
              onClick={onClose}
            />
          </div>
        </>
      }
      placement="right"
      closable={false}
      onClose={onClose}
      open={true}
      width={320}
    >
      <div style={infoDiv}>
        <div style={designationStyle}>
          <h3>{data?.designation?.designation}</h3>
        </div>

        <hr style={hrStyle} />
        <div style={emailDiv} onClick={copyEmail}>
          <span>{data.email}</span>
          <span style={{ marginLeft: "0.8rem" }}>
            <CopyOutlined />
          </span>{" "}
        </div>

        <div style={phoneDiv} onClick={copyPhone}>
          <span>{data?.mobileNumber}</span>
          {data?.mobileNumber !== null && (
            <span style={{ marginLeft: "0.4rem" }}>
              <CopyOutlined />
            </span>
          )}
        </div>
      </div>
      <div style={projectInfo}>
        <div style={{ display: "flex" }}>
          <h3 style={{ fontWeight: "bold" }}>Projects:</h3>
          {userProjects && userProjects[0]?.bandwidth !== undefined ? (
            userProjects[0]?.bandwidth !== 0 ? (
              <Tag
                color={colors.primary}
                style={{
                  width: "7rem",
                  height: "1.3rem",
                  marginLeft: "1rem",
                  fontWeight: "bold",
                }}
              >
                Bandwidth: {userProjects[0].bandwidth}%
              </Tag>
            ) : null
          ) : (
            <Tag
              color={colors.primary}
              style={{
                width: "7rem",
                height: "1.3rem",
                marginLeft: "1rem",
                fontWeight: "bold",
              }}
            >
              Bandwidth: 100%
            </Tag>
          )}
        </div>

        {userProjects && userProjects.length > 0 ? (
          <div>
            <table style={{ width: "100%", marginTop: "1rem" }}>
              <tbody>
                {userProjects.map((project, index) =>
                  project.projectName &&
                  project.allocation !== undefined &&
                  project.billable !== undefined ? (
                    <tr key={index}>
                      <td style={{ padding: "6px" }}>{project.projectName}</td>
                      <td style={{ padding: "6px" }}>{project.allocation}%</td>
                      <td style={{ padding: "6px" }}>
                        {project.billable ? (
                          <Tag
                            style={{
                              fontWeight: "bold",
                              color: colors.primary,
                            }}
                          >
                            Billable
                          </Tag>
                        ) : (
                          <Tag
                            style={{
                              fontWeight: "bold",
                              color: colors.primary,
                            }}
                          >
                            Non Billable
                          </Tag>
                        )}
                      </td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <p style={{ marginTop: "3rem" }}>(No projects found)</p>
        )}
      </div>

      <hr style={{ marginTop: "12px", marginBottom: "12px" }}></hr>
      <div style={skillInfo}>
        <h3 style={{ fontWeight: "bold", marginTop: "6px" }}>Skills:</h3>

        {userSkills && userSkills.length > 0 ? (
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "1rem" }}>
            {userSkills
              .sort((a: UserSkill, b: UserSkill) => {
                if (a.level === "EXPERT" && b.level !== "EXPERT") {
                  return -1;
                } else if (a.level !== "EXPERT" && b.level === "EXPERT") {
                  return 1;
                } else if (
                  a.level === "INTERMEDIATE" &&
                  b.level === "BEGINNER"
                ) {
                  return -1;
                } else if (a.level === "BEGINNER" && b.level !== "BEGINNER") {
                  return 1;
                }
                return 0;
              })
              .map((skill: UserSkill, index: number) => (
                <Tag
                  key={index}
                  style={{
                    display: "flex",
                    background: "#F9FAFA",
                    width: "fit-content",
                    marginBottom: "12px",
                    paddingRight: "0px",
                  }}
                >
                  <div style={{ fontWeight: "600", color: "#54595A" }}>
                    {skill.skill.name}
                  </div>

                  <Tag
                    color={getLevelColor(skill.level)}
                    style={{ marginLeft: "10px", marginRight: "0px" }}
                  >
                    <strong>{getLevelCase(skill.level)}</strong>
                  </Tag>
                </Tag>
              ))}
          </div>
        ) : (
          <p style={{ marginTop: "3rem" }}>(Skills not added yet)</p>
        )}
      </div>
    </Drawer>
  );
}

export default SidebarDrawer;
