export const layoutMain: React.CSSProperties = {
  width: "84%",
  height: "100vh",
  backgroundColor: "#f2f5fa",
  padding: "2rem",
};

export const mainContainer: React.CSSProperties = {
  minHeight: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "row",
};
