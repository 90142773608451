export const landingMain: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "1rem",
  minHeight: "50vh",
};

export const homePageMain: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  padding: "1rem",
  top: 0,
  overflowY: "auto",
  height: "90vh",
};

export const header: React.CSSProperties = {
  display: "flex",
  marginLeft: "3rem",
  flexDirection: "column",
  justifyContent: "center",
};

export const imageContainer: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const image: React.CSSProperties = {
  height: "25rem",
  margin: "0",
  padding: "0",
};

export const authorText: React.CSSProperties = {
  fontSize: 13,
  textAlign: "right",
  marginTop: 6,
};

export const selectBox: React.CSSProperties = {
  width: "77%",
  marginLeft: "0.2rem",
  marginBottom: "1rem",
  fontSize: "2rem",
  borderRadius: "10px",
};
