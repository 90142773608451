import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./Layout";
import LoginPage from "./Pages/LoginPage";
import "antd/dist/reset.css";
import ProfilePage from "./Pages/ProfilePage";
import ExpensesPage from "./Pages/ExpensesPage";
import SkillChart from "./Pages/OrganizationView/SkillChart";
import OrganizationPage from "./Pages/OrganizationPage";
import { HomePage } from "./Pages/HomePage";
import { Leaves } from "./Components/Leaves/Leaves";
import KnowledgeCenter from "./Pages/KnowledgeCenter";
import Resources from "./Components/KnowledgeCenter/Resources";
import OnboardingPage from "./Pages/OnboardingPage";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import SessionError from "./Pages/SessionError";
import ProjectPage from "./Pages/ProjectPage";
import { ConfigProvider } from "antd";
import theme from "./Theme/Theme";
import LoadingUser from "./Components/LoadingUser";
import LeavesReport from "./Components/Leaves/LeavesReport";

function App() {
  return (
    <div className="App">
      <ConfigProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/leaves-calendar/:projectHash" element={<Leaves />} />
            <Route path="/session-error" element={<SessionError />} />
            <Route path="/user-login" element={<LoadingUser />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<Layout />}>
              <Route path="/profile/:userId" element={<ProtectedRoutes />}>
                <Route path="/profile/:userId" element={<ProfilePage />} />
              </Route>
              <Route path="/" element={<ProtectedRoutes />}>
                <Route path="/" element={<HomePage />} />
              </Route>
              <Route path="/documents" element={<ProtectedRoutes />}>
                <Route path="/documents" element={<OnboardingPage />} />
              </Route>
              <Route path="/leaves" element={<ProtectedRoutes />}>
                <Route path="/leaves" element={<Leaves />} />
              </Route>
              <Route path="/leaves/report" element={<ProtectedRoutes />}>
                <Route path="/leaves/report" element={<LeavesReport />} />
              </Route>
              <Route path="/organizationPage" element={<ProtectedRoutes />}>
                <Route
                  path="/organizationPage"
                  element={<OrganizationPage />}
                />
              </Route>
              <Route path="/bills" element={<ProtectedRoutes />}>
                <Route path="/bills" element={<ExpensesPage />} />
              </Route>

              <Route path="/skillChart" element={<ProtectedRoutes />}>
                <Route path="/skillChart" element={<SkillChart />} />
              </Route>
              <Route path="/knowledgeCenter" element={<ProtectedRoutes />}>
                <Route path="/knowledgeCenter" element={<KnowledgeCenter />} />
              </Route>
              <Route path="/project" element={<ProtectedRoutes />}>
                <Route path="/project" element={<ProjectPage />} />
              </Route>
              <Route path="/resources/:id" element={<ProtectedRoutes />}>
                <Route path="/resources/:id" element={<Resources />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}

export default App;
