import React, { useEffect, useState } from "react";
import ProjectList from "./ProjectList";
import {
  Button,
  FloatButton,
  Input,
  Modal,
  AutoComplete,
  notification,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import useGet from "../../Hooks/useGet";
import User from "../../interface/User";
import useFetch from "../../Hooks/useFetch";
import { FilterData } from "../../interface/FilterData";
import { AllProject } from "../../interface/AllProject";
import Loader from "../Loader";
import { ReactComponent as ProjectGraphic } from "../../assets/project_team.svg";
import { userRoles } from "../../enums/Roles";
import { Typography } from "antd";

const { Title } = Typography;

const Project = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [managerName, setManagerName] = useState("");
  const [leadName, setLeadName] = useState("");
  const [loader, setLoader] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allProjectData, setAllProjectData] = useState<AllProject[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [filterData, setFilterData] = useState<FilterData[]>([]);
  const { call } = useFetch("POST");

  const userType = localStorage.getItem("role");

  const showModal = () => setIsModalOpen(true);
  const handleOk = () => {
    setName("");
    setDescription("");
    setManagerName("");
    setLeadName("");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setName("");
    setDescription("");
    setManagerName("");
    setLeadName("");
    setIsModalOpen(false);
  };

  const getData = (user: User[]) => {
    if (user) {
      setUsersList(user);
      let ArrayfilterData: FilterData[] = [];
      for (let i = 0; i < user.length; i++) {
        ArrayfilterData.push({
          level: user[i].name,
          value: user[i].name,
        });
      }
      setFilterData(ArrayfilterData);
    }
  };

  const getUsers = useGet(`/user/all`, getData);

  const getAllProject = (project: AllProject[]) => {
    if (project) setAllProjectData(project);
    setLoader(false);
  };

  const getAllProjectData = useGet(`/project/all`, getAllProject);

  const submitHandler = () => {
    const managerData = usersList.filter((user) => user.name === managerName);
    const leadData = usersList.filter((user) => user.name === leadName);
    if (
      name === "" ||
      description === "" ||
      managerName === "" ||
      leadName === ""
    ) {
      notification.warning({
        message: "Fields Can't be empty",
      });
    } else {
      const projectData = {
        name: name,
        description: description,
        managerId: managerData[0].id,
        leadId: leadData[0].id,
      };

      call(
        "/project",
        projectData,
        (data) => {
          notification.success({
            message: "Project Added successfully",
          });
          setName("");
          setDescription("");
          setManagerName("");
          setLeadName("");
          getAllProjectData();
          setIsModalOpen(false);
        },
        (error) => {
          notification.warning({
            message: "Failed to add the project",
          });
        }
      );
    }
  };

  useEffect(() => {
    setLoader(true);
    if (userType === userRoles.Admin || userType === userRoles.Allocation) {
      getUsers();
    }
    getAllProjectData();
  }, [getUsers, getAllProjectData, userType]);

  const selectManagerName = (value: string) => setManagerName(value);
  const selectLeadName = (value: string) => setLeadName(value);
  const clearManagerName = (value: string) => setManagerName("");
  const clearLeadName = (value: string) => setLeadName("");

  return (
    <>
      {loader ? (
        <Loader loading={loader} message="Loading Project" />
      ) : (
        <div
          style={{
            height: "80vh",
            display: "flex",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            <div className="project-body">
              {allProjectData ? (
                allProjectData.map((project, index) => {
                  return (
                    <ProjectList
                      key={index}
                      data={project}
                      userList={usersList}
                      filterData={filterData}
                      getAllProjectData={getAllProjectData}
                    />
                  );
                })
              ) : (
                <Title level={3}>No Projects to show</Title>
              )}
            </div>
            <div
              style={{
                padding: "1.5rem",
                marginRight: "1rem",
                display: "flex",
                justifyContent: "center",
                width: "25rem",
              }}
            >
              {<ProjectGraphic style={{ width: "100%" }} />}
            </div>
          </div>
          {(userType === userRoles.Admin ||
            userType === userRoles.Allocation) && (
            <FloatButton
              tooltip="Add New Project"
              icon={<PlusOutlined />}
              onClick={showModal}
            />
          )}
          <Modal
            style={{ top: "50px" }}
            title="Add New Project"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={true}
            footer={
              <Button
                key="submit"
                type="primary"
                onClick={submitHandler}
                style={{ marginTop: "1rem" }}
              >
                Save
              </Button>
            }
          >
            <Input
              style={{ marginTop: "0.5rem", width: "100%" }}
              placeholder="Name"
              allowClear
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              style={{ marginTop: "0.5rem", width: "100%" }}
              placeholder="Description"
              allowClear
              onChange={(e) => setDescription(e.target.value)}
            />
            <AutoComplete
              style={{ width: "100%", marginTop: "0.5rem" }}
              placeholder="Manager Name"
              options={filterData}
              filterOption={true}
              onSelect={selectManagerName}
              onChange={(value: string) => {
                if (value === "") clearManagerName(value);
              }}
            />
            <AutoComplete
              style={{ width: "100%", marginTop: "0.5rem" }}
              placeholder="Lead Name"
              className="search-user"
              options={filterData}
              filterOption={true}
              onSelect={selectLeadName}
              onChange={(value: string) => {
                if (value === "") clearLeadName(value);
              }}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default Project;
