import React, { useState } from "react";
import { ProjectUserMapping } from "../../interface/ProjectUserMapping";
import { Button, notification, Modal } from "antd";
import useFetch from "../../Hooks/useFetch";
import { card, userCard } from "../../Styles/Project/ProjectListStyles";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { userRoles } from "../../enums/Roles";
import User from "../../interface/User";

const ProjectUserCard = ({
  userData,
  getAllProjectData,
  id,
  userList,
}: {
  userData: ProjectUserMapping;
  getAllProjectData: () => void;
  id: number;
  userList: User[];
}) => {
  const userType = localStorage.getItem("role");

  const { call } = useFetch("DELETE");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const submitHandler = () => {
    const selectedUserData = userList.filter(
      (user) => user.name === userData.userName
    );
    const deleteUserData = {
      projectId: id,
      userId: selectedUserData[0].id,
    };

    call(
      `/project/delete-user`,
      deleteUserData,
      (data) => {
        notification.success({
          message: "User Deallocated",
        });
        getAllProjectData();
      },
      (error) => {
        notification.warning({
          message: "Failed to Change",
        });
      }
    );
    setIsModalOpen(false);
  };

  return (
    <div>
      <div style={{ ...userCard, ...card }}>
        <h3 style={{ width: "220px" }}>{userData.userName}</h3>
        <h3 style={{ width: "100px" }}>
          Billable: {userData.billable ? <CheckOutlined /> : <CloseOutlined />}
        </h3>
        <h3 style={{ width: "140px" }}>
          Allocation: {`${userData.availability}%`}
        </h3>
        {isModalOpen && (
          <Modal
            title="Warning"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
              <Button
                key="submit"
                type="primary"
                danger
                onClick={submitHandler}
                style={{ marginTop: "1rem" }}
              >
                Remove
              </Button>
            }
          >
            <p>Are you sure you want to remove this user?</p>
          </Modal>
        )}
        {(userType === userRoles.Admin ||
          userType === userRoles.Allocation) && (
          <Button
            key="submit"
            type="text"
            danger
            style={{ fontSize: 14, marginTop: "-8px", fontWeight: "" }}
            onClick={handleDelete}
          >
            Remove
          </Button>
        )}
      </div>
      <div
        style={{
          height: 1,
          background: "#e5f2f2",
          width: "90%",
          margin: "auto",
        }}
      />
    </div>
  );
};

export default ProjectUserCard;
